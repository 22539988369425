import dayjsBase from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import { capitalize } from "./index";

// Day.js docs reference: https://day.js.org/docs/en/display/format
export const time = (dayjsBase.extend(relativeTime) as any).extend(advancedFormat);

export enum TIME_BLOCKS {
  MORNING = "morning",
  AFTERNOON = "afternoon",
  EVENING = "evening",
}

export const getTimeBlock = (timestamp?: string | undefined) => {
  const hour = time(timestamp).hour();

  if (hour < 12) {
    return TIME_BLOCKS.MORNING;
  }

  if (hour < 17) {
    return TIME_BLOCKS.AFTERNOON;
  }

  return TIME_BLOCKS.EVENING;
};

export const convertTimeBlock = (block: TIME_BLOCKS) => {
  switch (block) {
    case TIME_BLOCKS.MORNING:
      return time().hour(0);
    case TIME_BLOCKS.AFTERNOON:
      return time().hour(12);
    case TIME_BLOCKS.EVENING:
      return time().hour(17);
    default:
      return time();
  }
};

const isWithin30Minutes = (timestamp: string) => {
  const then = time(timestamp);
  const now = time();
  return now.diff(then, "minute", true) < 30;
};

const isSameDay = (timestamp: string) => {
  const then = time(timestamp);
  const now = time();
  return then.isSame(now, "day");
};

const isYesterday = (timestamp: string) => {
  const then = time(timestamp);
  const now = time();
  return now.startOf("day").diff(then, "day", true) < 1;
};

const isPastWeek = (timestamp: string) => {
  const then = time(timestamp);
  const now = time();
  return now.startOf("day").diff(then, "day", true) < 7;
};

const isSameWeek = (timestamp: string) => {
  const then = time(timestamp);
  const now = time();
  return then.isSame(now, "week");
};

const isSameYear = (timestamp: string) => {
  const then = time(timestamp);
  const now = time();
  return then.isSame(now, "year");
};

export const formatTime = (timestamp: string) => {
  const then = time(timestamp);

  // Within the past 30 minutes
  // Examples: A few seconds ago, 5 minutes ago
  if (isWithin30Minutes(then)) {
    return capitalize(then.fromNow());
  }

  const timeBlock = getTimeBlock(timestamp);

  // Same day, more than 30 minutes ago
  // Examples: This morning, This evening
  if (isSameDay(then)) {
    return `This ${timeBlock}`;
  }

  // Yesterday
  // Examples: Yesterday evening
  if (isYesterday(then)) {
    return `Yesterday ${timeBlock}`;
  }

  // Within the past week
  // Examples: Last Friday afternoon, Tuesday morning
  if (isPastWeek(then)) {
    const thisWeek = isSameWeek(then);
    return `${!thisWeek ? "Last " : ""}${then.format("dddd")} ${timeBlock}`;
  }

  // Same year
  // Examples: January 10th, February 2nd
  if (isSameYear(then)) {
    return `${capitalize(timeBlock)} of ${then.format("MMMM Do")}`;
  }

  // Last year and earlier
  // Examples: April 17, 2018, October 24, 2019
  return `${capitalize(timeBlock)} of ${then.format("MMMM D, YYYY")}`;
};

export const formatTimeAbsolute = (timestamp: string) => {
  const then = time(timestamp);

  // Within the past 30 minutes
  // Examples: A few seconds ago, 5 minutes ago
  if (isWithin30Minutes(then)) {
    return capitalize(then.fromNow());
  }

  const timeBlock = getTimeBlock(timestamp);

  // Same day, more than 30 minutes ago
  // Examples: This morning, This evening
  if (isSameDay(then)) {
    return `This ${timeBlock}`;
  }

  // Yesterday
  // Examples: Yesterday evening
  if (isYesterday(then)) {
    return `Yesterday ${timeBlock}`;
  }

  // Within the past week
  // Examples: Last Friday afternoon, Tuesday morning
  if (isPastWeek(then)) {
    const thisWeek = isSameWeek(then);
    return `${!thisWeek ? "Last " : ""}${then.format("dddd")} ${timeBlock}`;
  }

  // Same year
  // Examples: January 10th, February 2nd
  if (isSameYear(then)) {
    return `${capitalize(timeBlock)} of ${then.format("MMMM Do")}`;
  }

  // Last year and earlier
  // Examples: April 17, 2018, October 24, 2019
  return `${capitalize(timeBlock)} of ${then.format("MMMM D, YYYY")}`;
};
