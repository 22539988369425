import { ApolloClient, DefaultOptions, from, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/link-context";
import { onError } from "@apollo/link-error";
import { createPersistedQueryLink } from "@apollo/link-persisted-queries";
import { RetryLink } from "@apollo/link-retry";
import { TokenRefreshLink } from "apollo-link-token-refresh";
import { loader } from "graphql.macro";
import {
  GetAccessTokenDocument,
  GetAccessTokenQuery,
  SetAuthDetailsDocument,
  SetAuthDetailsMutation,
  SetAuthDetailsMutationVariables,
} from "../generated/graphql";
import { history, ROUTES } from "../Router";
import { baseResolvers, initializeCache } from "./client";
import { possibleTypes } from "./introspection.json";

export const BASE_URL = process.env.REACT_APP_GROVE_API;

const tokenRefreshLink = new TokenRefreshLink({
  isTokenValidOrUndefined: () => {
    const data = cache.readQuery<GetAccessTokenQuery>({ query: GetAccessTokenDocument });
    const accessToken = data?.accessToken;

    if (!accessToken) {
      return true;
    }

    return Date.now() < parseInt(accessToken.expiresAt) * 1000;
  },
  accessTokenField: "accessToken",
  fetchAccessToken: () => {
    return fetch(`${BASE_URL}/refresh_token`, { method: "POST", credentials: "include" });
  },
  handleFetch: async (newAccessToken) => {
    console.info(`New access token: ${newAccessToken}`);
    client.mutate<SetAuthDetailsMutation, SetAuthDetailsMutationVariables>({
      mutation: SetAuthDetailsDocument,
      variables: { accessToken: newAccessToken },
    });
  },
  handleError: (err) => {
    console.warn(`Could not refresh access token: `, err);
    history.push(ROUTES.LOGIN);
  },
}) as any;

const authLink = setContext(async (_, { headers }) => {
  const res = await client.query<GetAccessTokenQuery>({ query: GetAccessTokenDocument });
  const accessToken = res.data?.accessToken?.token;

  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : "",
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  console.info("GraphQL Error: ", JSON.stringify(graphQLErrors, null, 2));
  console.info("Network Error: ", JSON.stringify(networkError, null, 2));

  if (graphQLErrors) {
    // graphQLErrors.forEach(({ extensions, message }) => {
    //   switch (extensions.code) {
    //     case ERROR_CODES.BAD_USER_INPUT:
    //       setError({ title: ERROR_MESSAGES.BAD_USER_INPUT, message });
    //       break;
    //     case ERROR_CODES.FORBIDDEN:
    //     case ERROR_CODES.UNAUTHENTICATED:
    //       logout();
    //       break;
    //     default:
    //       break;
    //   }
    // });
  }

  if (networkError) {
    // setError({ message: ERROR_MESSAGES.NETWORK_ERROR });
  }
});

const persistedQueryLink = createPersistedQueryLink({ useGETForHashedQueries: true });

const retryLink = new RetryLink();

const httpLink = new HttpLink({
  uri: `${BASE_URL}/graphql`,
  credentials: "include",
});

const links = [tokenRefreshLink, authLink, errorLink, persistedQueryLink, retryLink, httpLink];

const typeDefs = loader("./client/schema.graphql");

const cache = new InMemoryCache({
  possibleTypes,
  typePolicies: {
    Query: {
      fields: {
        note: (existingData, { args, toReference }) => {
          return existingData || toReference({ __typename: "Note", id: args?.where?.id });
        },
      },
    },
  },
});

const defaultOptions: DefaultOptions = {
  watchQuery: {
    partialRefetch: true,
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
  },
  query: {
    fetchPolicy: "cache-first",
    errorPolicy: "all",
  },
  mutate: {
    errorPolicy: "all",
  },
};

const client = new ApolloClient({
  link: from(links),
  cache,
  resolvers: baseResolvers as any,
  typeDefs,
  defaultOptions,
});

initializeCache(cache);
client.onResetStore(async () => initializeCache(cache));
client.onClearStore(async () => initializeCache(cache));

export default client;
export * from "./client";
