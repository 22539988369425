import { createBrowserHistory } from "history";
import React, { FC, lazy, Suspense, useCallback } from "react";
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
  Router as BaseRouter,
  Switch,
} from "react-router-dom";
import { useAuth } from "./contexts";

export enum ROUTES {
  LOGIN = "/login",
  REGISTER = "/register",
  ROOT = "/",
  NOTE = "/note",
  CREATE_NOTE = "/note/new",
  PROFILE = "/person",
}

const PrivateRoute: FC<RouteProps> = ({ component, ...rest }) => {
  const { accessToken } = useAuth();

  const renderRoute = useCallback(
    (props: RouteComponentProps) => {
      if (!accessToken) {
        return <Redirect to={{ pathname: ROUTES.LOGIN, state: { from: props.location } }} />;
      }

      const Component = component as any;
      return <Component {...props} />;
    },
    [accessToken, component]
  );

  return <Route {...rest} render={renderRoute} />;
};

export const history = createBrowserHistory();

const Header = lazy(() => import("./screens/Header"));
const Login = lazy(() => import("./screens/Login"));
const Register = lazy(() => import("./screens/Register"));
const Home = lazy(() => import("./screens/Home"));
const CreateNote = lazy(() => import("./screens/CreateNote"));
const Note = lazy(() => import("./screens/Note"));
const Profile = lazy(() => import("./screens/Profile"));

const Router: FC = () => {
  const { loading } = useAuth();

  if (loading) return null;

  return (
    <BaseRouter history={history}>
      <Suspense fallback={null}>
        <Header />
        <Switch>
          <Route path={ROUTES.LOGIN} component={Login} />
          <Route path={ROUTES.REGISTER} component={Register} />
          <PrivateRoute path={ROUTES.ROOT} exact component={Home} />
          <PrivateRoute path={`${ROUTES.CREATE_NOTE}`} component={CreateNote} />
          <PrivateRoute path={`${ROUTES.NOTE}/:id`} component={Note} />
          <PrivateRoute path={`${ROUTES.PROFILE}/:id`} component={Profile} />
        </Switch>
      </Suspense>
    </BaseRouter>
  );
};

export default Router;
