import { v4 as uuidv4 } from "uuid";
export * from "./time";

type PersonWithName = { firstName?: string | null; lastName?: string | null } | null;
export const getFullName = (person: PersonWithName) => {
  const parts = [person?.firstName ?? "", person?.lastName ?? ""];
  return parts.filter(Boolean).join(" ");
};

type PersonWithWork = { jobTitle?: string | null; company?: string | null } | null;
export const getWorkInfo = (person: PersonWithWork) => {
  const parts = [person?.jobTitle ?? "", person?.company ?? ""];
  return parts.filter(Boolean).join(" @ ");
};

export const capitalize = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

/* eslint-disable @typescript-eslint/no-empty-function */
export const noop = () => {};
export const noopAsync = async () => {};
/* eslint-enable @typescript-eslint/no-empty-function */

const optimisticPrefix = "OPT-";
export const getOptimisticId = () => optimisticPrefix + uuidv4();
export const isOptimisticId = (id: string) => id.startsWith(optimisticPrefix);

export const sortByKey = (key: string) => (a: any, b: any) => a[key].localeCompare(b[key]);
export const sortByKeyDesc = (key: string) => (a: any, b: any) => b[key].localeCompare(a[key]);
