import { ApolloClient, InMemoryCache } from "@apollo/client";
import jwtDecode from "jwt-decode";
import {
  GetAccessTokenDocument,
  GetAccessTokenQuery,
  GetCurrentUserDocument,
  GetCurrentUserQuery,
  Resolvers,
} from "../../generated/graphql";

interface ApolloContext {
  client: ApolloClient<object>;
  cache: InMemoryCache;
  getCacheKey(identifiers: { __typename: string; id: string }): string;
}

export interface Token {
  iat: string;
  exp: string;
  jti: string;
  userId: string;
  email: string;
  username: string;
  firstName: string;
  lastName: string;
}

export const initializeCache = (cache: InMemoryCache) => {
  cache.writeQuery<GetAccessTokenQuery>({
    query: GetAccessTokenDocument,
    data: { accessToken: null },
  });

  cache.writeQuery<GetCurrentUserQuery>({
    query: GetCurrentUserDocument,
    data: { currentUser: null },
  });
};

export const baseResolvers: Resolvers<ApolloContext> = {
  Mutation: {
    setAuthDetails: (_, { accessToken }, { cache }) => {
      if (!accessToken) {
        initializeCache(cache);
        return null;
      }

      const { iat, exp, jti, userId, ...user } = jwtDecode<Token>(accessToken);
      const tokenPayload = { token: accessToken, id: jti, expiresAt: exp };
      const currentUser = { id: userId, ...user };

      cache.writeQuery<GetAccessTokenQuery>({
        query: GetAccessTokenDocument,
        data: { accessToken: tokenPayload },
      });

      cache.writeQuery<GetCurrentUserQuery>({
        query: GetCurrentUserDocument,
        data: { currentUser },
      });

      return null;
    },
  },
};
