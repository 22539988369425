import { Ref, useEffect, useRef } from "react";

export function useClickOutside(onClickOutside: () => void): Ref<any> {
  const nodeRef = useRef<any>();

  const handleClick = (e: MouseEvent) => {
    if (!nodeRef?.current?.contains(e.target)) {
      onClickOutside();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return nodeRef;
}
