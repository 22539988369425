import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from "graphql";
import gql from "graphql-tag";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
export type Maybe<T> = T | null;
export type RequireFields<T, K extends keyof T> = { [X in Exclude<keyof T, K>]?: T[X] } &
  { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type Query = {
  readonly __typename?: "Query";
  readonly accessToken?: Maybe<AccessToken>;
  readonly currentUser?: Maybe<User>;
  readonly me?: Maybe<ContextUser>;
  readonly note?: Maybe<Note>;
  readonly notes?: Maybe<ReadonlyArray<Note>>;
  readonly notesRaw: ReadonlyArray<Note>;
  readonly notesSearch?: Maybe<ReadonlyArray<Note>>;
  readonly people?: Maybe<ReadonlyArray<Person>>;
  readonly peopleGroups?: Maybe<ReadonlyArray<PeopleGroup>>;
  readonly peopleRaw: ReadonlyArray<Person>;
  readonly peopleSearch?: Maybe<ReadonlyArray<Person>>;
  readonly person?: Maybe<Person>;
  readonly refreshToken?: Maybe<RefreshToken>;
  readonly refreshTokens: ReadonlyArray<RefreshToken>;
  readonly search?: Maybe<ReadonlyArray<SearchResult>>;
  readonly user?: Maybe<User>;
  readonly users: ReadonlyArray<User>;
};

export type QueryNoteArgs = {
  where: NoteWhereUniqueInput;
};

export type QueryNotesArgs = {
  after?: Maybe<NoteWhereUniqueInput>;
  before?: Maybe<NoteWhereUniqueInput>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
  orderBy?: Maybe<NoteOrderByInput>;
  skip?: Maybe<Scalars["Int"]>;
  where?: Maybe<NoteWhereInput>;
};

export type QueryNotesRawArgs = {
  where?: Maybe<NoteWhereInput>;
  orderBy?: Maybe<NoteOrderByInput>;
  skip?: Maybe<Scalars["Int"]>;
  after?: Maybe<NoteWhereUniqueInput>;
  before?: Maybe<NoteWhereUniqueInput>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
};

export type QueryNotesSearchArgs = {
  searchString: Scalars["String"];
};

export type QueryPeopleArgs = {
  after?: Maybe<PersonWhereUniqueInput>;
  before?: Maybe<PersonWhereUniqueInput>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
  orderBy?: Maybe<PersonOrderByInput>;
  skip?: Maybe<Scalars["Int"]>;
  where?: Maybe<PersonWhereInput>;
};

export type QueryPeopleGroupsArgs = {
  after?: Maybe<PersonWhereUniqueInput>;
  before?: Maybe<PersonWhereUniqueInput>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
  orderBy?: Maybe<PersonOrderByInput>;
  skip?: Maybe<Scalars["Int"]>;
  where?: Maybe<PersonWhereInput>;
};

export type QueryPeopleRawArgs = {
  where?: Maybe<PersonWhereInput>;
  orderBy?: Maybe<PersonOrderByInput>;
  skip?: Maybe<Scalars["Int"]>;
  after?: Maybe<PersonWhereUniqueInput>;
  before?: Maybe<PersonWhereUniqueInput>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
};

export type QueryPeopleSearchArgs = {
  searchString: Scalars["String"];
};

export type QueryPersonArgs = {
  where: PersonWhereUniqueInput;
};

export type QueryRefreshTokenArgs = {
  where: RefreshTokenWhereUniqueInput;
};

export type QueryRefreshTokensArgs = {
  where?: Maybe<RefreshTokenWhereInput>;
  skip?: Maybe<Scalars["Int"]>;
  after?: Maybe<RefreshTokenWhereUniqueInput>;
  before?: Maybe<RefreshTokenWhereUniqueInput>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
};

export type QuerySearchArgs = {
  searchString: Scalars["String"];
};

export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};

export type QueryUsersArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<UserOrderByInput>;
  skip?: Maybe<Scalars["Int"]>;
  after?: Maybe<UserWhereUniqueInput>;
  before?: Maybe<UserWhereUniqueInput>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
};

export type ContextUser = {
  readonly __typename?: "ContextUser";
  readonly id?: Maybe<Scalars["String"]>;
  readonly username?: Maybe<Scalars["String"]>;
  readonly email?: Maybe<Scalars["String"]>;
};

export type NoteWhereUniqueInput = {
  readonly id?: Maybe<Scalars["String"]>;
};

export type Note = {
  readonly __typename?: "Note";
  readonly id: Scalars["String"];
  readonly createdAt: Scalars["DateTime"];
  readonly metAt: Scalars["DateTime"];
  readonly updatedAt: Scalars["DateTime"];
  readonly author: User;
  readonly person: Person;
  readonly content: Scalars["String"];
};

export type User = {
  readonly __typename?: "User";
  readonly id: Scalars["String"];
  readonly email: Scalars["String"];
  readonly username: Scalars["String"];
  readonly password: Scalars["String"];
  readonly firstName: Scalars["String"];
  readonly lastName: Scalars["String"];
  readonly notes: ReadonlyArray<Note>;
};

export type UserNotesArgs = {
  where?: Maybe<NoteWhereInput>;
  orderBy?: Maybe<NoteOrderByInput>;
  skip?: Maybe<Scalars["Int"]>;
  after?: Maybe<NoteWhereUniqueInput>;
  before?: Maybe<NoteWhereUniqueInput>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
};

export type NoteWhereInput = {
  readonly id?: Maybe<StringFilter>;
  readonly createdAt?: Maybe<DateTimeFilter>;
  readonly metAt?: Maybe<DateTimeFilter>;
  readonly updatedAt?: Maybe<DateTimeFilter>;
  readonly content?: Maybe<StringFilter>;
  readonly AND?: Maybe<ReadonlyArray<NoteWhereInput>>;
  readonly OR?: Maybe<ReadonlyArray<NoteWhereInput>>;
  readonly NOT?: Maybe<ReadonlyArray<NoteWhereInput>>;
  readonly person?: Maybe<PersonWhereInput>;
};

export type StringFilter = {
  readonly equals?: Maybe<Scalars["String"]>;
  readonly not?: Maybe<Scalars["String"]>;
  readonly in?: Maybe<ReadonlyArray<Scalars["String"]>>;
  readonly notIn?: Maybe<ReadonlyArray<Scalars["String"]>>;
  readonly lt?: Maybe<Scalars["String"]>;
  readonly lte?: Maybe<Scalars["String"]>;
  readonly gt?: Maybe<Scalars["String"]>;
  readonly gte?: Maybe<Scalars["String"]>;
  readonly contains?: Maybe<Scalars["String"]>;
  readonly startsWith?: Maybe<Scalars["String"]>;
  readonly endsWith?: Maybe<Scalars["String"]>;
};

export type DateTimeFilter = {
  readonly equals?: Maybe<Scalars["DateTime"]>;
  readonly not?: Maybe<Scalars["DateTime"]>;
  readonly in?: Maybe<ReadonlyArray<Scalars["DateTime"]>>;
  readonly notIn?: Maybe<ReadonlyArray<Scalars["DateTime"]>>;
  readonly lt?: Maybe<Scalars["DateTime"]>;
  readonly lte?: Maybe<Scalars["DateTime"]>;
  readonly gt?: Maybe<Scalars["DateTime"]>;
  readonly gte?: Maybe<Scalars["DateTime"]>;
};

export type PersonWhereInput = {
  readonly id?: Maybe<StringFilter>;
  readonly createdAt?: Maybe<DateTimeFilter>;
  readonly updatedAt?: Maybe<DateTimeFilter>;
  readonly contactId?: Maybe<NullableStringFilter>;
  readonly firstName?: Maybe<NullableStringFilter>;
  readonly middleName?: Maybe<NullableStringFilter>;
  readonly lastName?: Maybe<NullableStringFilter>;
  readonly emails?: Maybe<EmailFilter>;
  readonly phones?: Maybe<PhoneFilter>;
  readonly addresses?: Maybe<AddressFilter>;
  readonly birthday?: Maybe<NullableDateTimeFilter>;
  readonly thumbnail?: Maybe<NullableStringFilter>;
  readonly company?: Maybe<NullableStringFilter>;
  readonly jobTitle?: Maybe<NullableStringFilter>;
  readonly notes?: Maybe<NoteFilter>;
  readonly AND?: Maybe<ReadonlyArray<PersonWhereInput>>;
  readonly OR?: Maybe<ReadonlyArray<PersonWhereInput>>;
  readonly NOT?: Maybe<ReadonlyArray<PersonWhereInput>>;
};

export type NullableStringFilter = {
  readonly equals?: Maybe<Scalars["String"]>;
  readonly not?: Maybe<Scalars["String"]>;
  readonly in?: Maybe<ReadonlyArray<Scalars["String"]>>;
  readonly notIn?: Maybe<ReadonlyArray<Scalars["String"]>>;
  readonly lt?: Maybe<Scalars["String"]>;
  readonly lte?: Maybe<Scalars["String"]>;
  readonly gt?: Maybe<Scalars["String"]>;
  readonly gte?: Maybe<Scalars["String"]>;
  readonly contains?: Maybe<Scalars["String"]>;
  readonly startsWith?: Maybe<Scalars["String"]>;
  readonly endsWith?: Maybe<Scalars["String"]>;
};

export type EmailFilter = {
  readonly every?: Maybe<EmailWhereInput>;
  readonly some?: Maybe<EmailWhereInput>;
  readonly none?: Maybe<EmailWhereInput>;
};

export type EmailWhereInput = {
  readonly id?: Maybe<StringFilter>;
  readonly label?: Maybe<NullableStringFilter>;
  readonly email?: Maybe<StringFilter>;
  readonly AND?: Maybe<ReadonlyArray<EmailWhereInput>>;
  readonly OR?: Maybe<ReadonlyArray<EmailWhereInput>>;
  readonly NOT?: Maybe<ReadonlyArray<EmailWhereInput>>;
  readonly person?: Maybe<PersonWhereInput>;
};

export type PhoneFilter = {
  readonly every?: Maybe<PhoneWhereInput>;
  readonly some?: Maybe<PhoneWhereInput>;
  readonly none?: Maybe<PhoneWhereInput>;
};

export type PhoneWhereInput = {
  readonly id?: Maybe<StringFilter>;
  readonly label?: Maybe<NullableStringFilter>;
  readonly number?: Maybe<StringFilter>;
  readonly AND?: Maybe<ReadonlyArray<PhoneWhereInput>>;
  readonly OR?: Maybe<ReadonlyArray<PhoneWhereInput>>;
  readonly NOT?: Maybe<ReadonlyArray<PhoneWhereInput>>;
  readonly person?: Maybe<PersonWhereInput>;
};

export type AddressFilter = {
  readonly every?: Maybe<AddressWhereInput>;
  readonly some?: Maybe<AddressWhereInput>;
  readonly none?: Maybe<AddressWhereInput>;
};

export type AddressWhereInput = {
  readonly id?: Maybe<StringFilter>;
  readonly label?: Maybe<NullableStringFilter>;
  readonly street?: Maybe<NullableStringFilter>;
  readonly city?: Maybe<NullableStringFilter>;
  readonly state?: Maybe<NullableStringFilter>;
  readonly zipcode?: Maybe<NullableStringFilter>;
  readonly country?: Maybe<NullableStringFilter>;
  readonly AND?: Maybe<ReadonlyArray<AddressWhereInput>>;
  readonly OR?: Maybe<ReadonlyArray<AddressWhereInput>>;
  readonly NOT?: Maybe<ReadonlyArray<AddressWhereInput>>;
  readonly person?: Maybe<PersonWhereInput>;
};

export type NullableDateTimeFilter = {
  readonly equals?: Maybe<Scalars["DateTime"]>;
  readonly not?: Maybe<Scalars["DateTime"]>;
  readonly in?: Maybe<ReadonlyArray<Scalars["DateTime"]>>;
  readonly notIn?: Maybe<ReadonlyArray<Scalars["DateTime"]>>;
  readonly lt?: Maybe<Scalars["DateTime"]>;
  readonly lte?: Maybe<Scalars["DateTime"]>;
  readonly gt?: Maybe<Scalars["DateTime"]>;
  readonly gte?: Maybe<Scalars["DateTime"]>;
};

export type NoteFilter = {
  readonly every?: Maybe<NoteWhereInput>;
  readonly some?: Maybe<NoteWhereInput>;
  readonly none?: Maybe<NoteWhereInput>;
};

export type NoteOrderByInput = {
  readonly id?: Maybe<OrderByArg>;
  readonly createdAt?: Maybe<OrderByArg>;
  readonly metAt?: Maybe<OrderByArg>;
  readonly updatedAt?: Maybe<OrderByArg>;
  readonly person?: Maybe<OrderByArg>;
  readonly content?: Maybe<OrderByArg>;
};

export enum OrderByArg {
  Asc = "asc",
  Desc = "desc",
}

export type Person = {
  readonly __typename?: "Person";
  readonly id: Scalars["String"];
  readonly createdBy: User;
  readonly createdAt: Scalars["DateTime"];
  readonly updatedAt: Scalars["DateTime"];
  readonly contactId?: Maybe<Scalars["String"]>;
  readonly firstName?: Maybe<Scalars["String"]>;
  readonly middleName?: Maybe<Scalars["String"]>;
  readonly lastName?: Maybe<Scalars["String"]>;
  readonly emails: ReadonlyArray<Email>;
  readonly phones: ReadonlyArray<Phone>;
  readonly addresses: ReadonlyArray<Address>;
  readonly birthday?: Maybe<Scalars["DateTime"]>;
  readonly thumbnail?: Maybe<Scalars["String"]>;
  readonly company?: Maybe<Scalars["String"]>;
  readonly jobTitle?: Maybe<Scalars["String"]>;
  readonly notes: ReadonlyArray<Note>;
};

export type PersonEmailsArgs = {
  skip?: Maybe<Scalars["Int"]>;
  after?: Maybe<EmailWhereUniqueInput>;
  before?: Maybe<EmailWhereUniqueInput>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
};

export type PersonPhonesArgs = {
  skip?: Maybe<Scalars["Int"]>;
  after?: Maybe<PhoneWhereUniqueInput>;
  before?: Maybe<PhoneWhereUniqueInput>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
};

export type PersonAddressesArgs = {
  skip?: Maybe<Scalars["Int"]>;
  after?: Maybe<AddressWhereUniqueInput>;
  before?: Maybe<AddressWhereUniqueInput>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
};

export type PersonNotesArgs = {
  where?: Maybe<NoteWhereInput>;
  orderBy?: Maybe<NoteOrderByInput>;
  skip?: Maybe<Scalars["Int"]>;
  after?: Maybe<NoteWhereUniqueInput>;
  before?: Maybe<NoteWhereUniqueInput>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
};

export type EmailWhereUniqueInput = {
  readonly id?: Maybe<Scalars["String"]>;
};

export type Email = {
  readonly __typename?: "Email";
  readonly id: Scalars["String"];
  readonly label?: Maybe<Scalars["String"]>;
  readonly email: Scalars["String"];
};

export type PhoneWhereUniqueInput = {
  readonly id?: Maybe<Scalars["String"]>;
};

export type Phone = {
  readonly __typename?: "Phone";
  readonly id: Scalars["String"];
  readonly label?: Maybe<Scalars["String"]>;
  readonly number: Scalars["String"];
};

export type AddressWhereUniqueInput = {
  readonly id?: Maybe<Scalars["String"]>;
};

export type Address = {
  readonly __typename?: "Address";
  readonly id: Scalars["String"];
  readonly label?: Maybe<Scalars["String"]>;
  readonly street?: Maybe<Scalars["String"]>;
  readonly city?: Maybe<Scalars["String"]>;
  readonly state?: Maybe<Scalars["String"]>;
  readonly zipcode?: Maybe<Scalars["String"]>;
  readonly country?: Maybe<Scalars["String"]>;
};

export type PersonWhereUniqueInput = {
  readonly id?: Maybe<Scalars["String"]>;
  readonly contactId?: Maybe<Scalars["String"]>;
};

export type PersonOrderByInput = {
  readonly id?: Maybe<OrderByArg>;
  readonly createdAt?: Maybe<OrderByArg>;
  readonly updatedAt?: Maybe<OrderByArg>;
  readonly contactId?: Maybe<OrderByArg>;
  readonly firstName?: Maybe<OrderByArg>;
  readonly middleName?: Maybe<OrderByArg>;
  readonly lastName?: Maybe<OrderByArg>;
  readonly birthday?: Maybe<OrderByArg>;
  readonly thumbnail?: Maybe<OrderByArg>;
  readonly company?: Maybe<OrderByArg>;
  readonly jobTitle?: Maybe<OrderByArg>;
};

export type PeopleGroup = {
  readonly __typename?: "PeopleGroup";
  readonly key?: Maybe<Scalars["String"]>;
  readonly people?: Maybe<ReadonlyArray<Person>>;
};

export type RefreshTokenWhereUniqueInput = {
  readonly id?: Maybe<Scalars["String"]>;
  readonly hash?: Maybe<Scalars["String"]>;
  readonly parent?: Maybe<Scalars["String"]>;
};

export type RefreshToken = {
  readonly __typename?: "RefreshToken";
  readonly id: Scalars["String"];
  readonly hash: Scalars["String"];
  readonly userId: Scalars["String"];
  readonly device?: Maybe<Device>;
  readonly expiration: Scalars["DateTime"];
  readonly parent?: Maybe<Scalars["String"]>;
  readonly valid: Scalars["Boolean"];
};

export type Device = {
  readonly __typename?: "Device";
  readonly id: Scalars["String"];
  readonly name: Scalars["String"];
  readonly userId: Scalars["String"];
};

export type RefreshTokenWhereInput = {
  readonly id?: Maybe<StringFilter>;
  readonly hash?: Maybe<StringFilter>;
  readonly expiration?: Maybe<DateTimeFilter>;
  readonly parent?: Maybe<NullableStringFilter>;
  readonly valid?: Maybe<BooleanFilter>;
  readonly AND?: Maybe<ReadonlyArray<RefreshTokenWhereInput>>;
  readonly OR?: Maybe<ReadonlyArray<RefreshTokenWhereInput>>;
  readonly NOT?: Maybe<ReadonlyArray<RefreshTokenWhereInput>>;
  readonly device?: Maybe<DeviceWhereInput>;
};

export type BooleanFilter = {
  readonly equals?: Maybe<Scalars["Boolean"]>;
  readonly not?: Maybe<Scalars["Boolean"]>;
};

export type DeviceWhereInput = {
  readonly id?: Maybe<StringFilter>;
  readonly name?: Maybe<StringFilter>;
  readonly refreshTokens?: Maybe<RefreshTokenFilter>;
  readonly AND?: Maybe<ReadonlyArray<DeviceWhereInput>>;
  readonly OR?: Maybe<ReadonlyArray<DeviceWhereInput>>;
  readonly NOT?: Maybe<ReadonlyArray<DeviceWhereInput>>;
};

export type RefreshTokenFilter = {
  readonly every?: Maybe<RefreshTokenWhereInput>;
  readonly some?: Maybe<RefreshTokenWhereInput>;
  readonly none?: Maybe<RefreshTokenWhereInput>;
};

export type SearchResult = Person | Note;

export type UserWhereUniqueInput = {
  readonly id?: Maybe<Scalars["String"]>;
  readonly email?: Maybe<Scalars["String"]>;
  readonly username?: Maybe<Scalars["String"]>;
};

export type UserWhereInput = {
  readonly id?: Maybe<StringFilter>;
  readonly email?: Maybe<StringFilter>;
  readonly username?: Maybe<StringFilter>;
  readonly password?: Maybe<StringFilter>;
  readonly firstName?: Maybe<StringFilter>;
  readonly lastName?: Maybe<StringFilter>;
  readonly notes?: Maybe<NoteFilter>;
  readonly persons?: Maybe<PersonFilter>;
  readonly AND?: Maybe<ReadonlyArray<UserWhereInput>>;
  readonly OR?: Maybe<ReadonlyArray<UserWhereInput>>;
  readonly NOT?: Maybe<ReadonlyArray<UserWhereInput>>;
};

export type PersonFilter = {
  readonly every?: Maybe<PersonWhereInput>;
  readonly some?: Maybe<PersonWhereInput>;
  readonly none?: Maybe<PersonWhereInput>;
};

export type UserOrderByInput = {
  readonly id?: Maybe<OrderByArg>;
  readonly email?: Maybe<OrderByArg>;
  readonly username?: Maybe<OrderByArg>;
  readonly password?: Maybe<OrderByArg>;
  readonly firstName?: Maybe<OrderByArg>;
  readonly lastName?: Maybe<OrderByArg>;
};

export type Mutation = {
  readonly __typename?: "Mutation";
  readonly addDeviceToken?: Maybe<ReadonlyArray<Scalars["ID"]>>;
  readonly createNote?: Maybe<Note>;
  readonly createOneNote: Note;
  readonly createOneUser: User;
  readonly deleteNote?: Maybe<Note>;
  readonly login?: Maybe<AuthPayload>;
  readonly logout?: Maybe<Scalars["Boolean"]>;
  readonly refreshTokens?: Maybe<AuthPayload>;
  readonly register?: Maybe<AuthPayload>;
  readonly setAuthDetails?: Maybe<Scalars["String"]>;
  readonly updateOnePerson?: Maybe<Person>;
  readonly updatePerson?: Maybe<Person>;
};

export type MutationAddDeviceTokenArgs = {
  deviceToken: Scalars["String"];
};

export type MutationCreateNoteArgs = {
  person: PersonInput;
  metAt?: Maybe<Scalars["String"]>;
  content: Scalars["String"];
};

export type MutationCreateOneNoteArgs = {
  data: NoteCreateInput;
};

export type MutationCreateOneUserArgs = {
  data: UserCreateInput;
};

export type MutationDeleteNoteArgs = {
  id: Scalars["String"];
};

export type MutationLoginArgs = {
  username: Scalars["String"];
  password: Scalars["String"];
  device?: Maybe<DeviceInput>;
};

export type MutationRegisterArgs = {
  user: UserCreateInput;
  device?: Maybe<DeviceInput>;
};

export type MutationSetAuthDetailsArgs = {
  accessToken: Scalars["String"];
};

export type MutationUpdateOnePersonArgs = {
  data: PersonUpdateInput;
  where: PersonWhereUniqueInput;
};

export type MutationUpdatePersonArgs = {
  id: Scalars["ID"];
  person: PersonInput;
};

export type UserCreateInput = {
  readonly id?: Maybe<Scalars["String"]>;
  readonly email: Scalars["String"];
  readonly username: Scalars["String"];
  readonly password: Scalars["String"];
  readonly firstName: Scalars["String"];
  readonly lastName: Scalars["String"];
  readonly deviceTokens?: Maybe<UserCreatedeviceTokensInput>;
  readonly notes?: Maybe<NoteCreateManyWithoutAuthorInput>;
  readonly persons?: Maybe<PersonCreateManyWithoutCreatedByInput>;
};

export type UserCreatedeviceTokensInput = {
  readonly set?: Maybe<ReadonlyArray<Scalars["String"]>>;
};

export type NoteCreateManyWithoutAuthorInput = {
  readonly create?: Maybe<ReadonlyArray<NoteCreateWithoutAuthorInput>>;
  readonly connect?: Maybe<ReadonlyArray<NoteWhereUniqueInput>>;
};

export type NoteCreateWithoutAuthorInput = {
  readonly id?: Maybe<Scalars["String"]>;
  readonly createdAt?: Maybe<Scalars["DateTime"]>;
  readonly metAt?: Maybe<Scalars["DateTime"]>;
  readonly updatedAt?: Maybe<Scalars["DateTime"]>;
  readonly content: Scalars["String"];
  readonly person: PersonCreateOneWithoutNotesInput;
};

export type PersonCreateOneWithoutNotesInput = {
  readonly create?: Maybe<PersonCreateWithoutNotesInput>;
  readonly connect?: Maybe<PersonWhereUniqueInput>;
};

export type PersonCreateWithoutNotesInput = {
  readonly id?: Maybe<Scalars["String"]>;
  readonly createdAt?: Maybe<Scalars["DateTime"]>;
  readonly updatedAt?: Maybe<Scalars["DateTime"]>;
  readonly contactId?: Maybe<Scalars["String"]>;
  readonly firstName?: Maybe<Scalars["String"]>;
  readonly middleName?: Maybe<Scalars["String"]>;
  readonly lastName?: Maybe<Scalars["String"]>;
  readonly birthday?: Maybe<Scalars["DateTime"]>;
  readonly thumbnail?: Maybe<Scalars["String"]>;
  readonly company?: Maybe<Scalars["String"]>;
  readonly jobTitle?: Maybe<Scalars["String"]>;
  readonly emails?: Maybe<EmailCreateManyWithoutPersonInput>;
  readonly phones?: Maybe<PhoneCreateManyWithoutPersonInput>;
  readonly addresses?: Maybe<AddressCreateManyWithoutPersonInput>;
};

export type EmailCreateManyWithoutPersonInput = {
  readonly create?: Maybe<ReadonlyArray<EmailCreateWithoutPersonInput>>;
  readonly connect?: Maybe<ReadonlyArray<EmailWhereUniqueInput>>;
};

export type EmailCreateWithoutPersonInput = {
  readonly id?: Maybe<Scalars["String"]>;
  readonly label?: Maybe<Scalars["String"]>;
  readonly email: Scalars["String"];
};

export type PhoneCreateManyWithoutPersonInput = {
  readonly create?: Maybe<ReadonlyArray<PhoneCreateWithoutPersonInput>>;
  readonly connect?: Maybe<ReadonlyArray<PhoneWhereUniqueInput>>;
};

export type PhoneCreateWithoutPersonInput = {
  readonly id?: Maybe<Scalars["String"]>;
  readonly label?: Maybe<Scalars["String"]>;
  readonly number: Scalars["String"];
};

export type AddressCreateManyWithoutPersonInput = {
  readonly create?: Maybe<ReadonlyArray<AddressCreateWithoutPersonInput>>;
  readonly connect?: Maybe<ReadonlyArray<AddressWhereUniqueInput>>;
};

export type AddressCreateWithoutPersonInput = {
  readonly id?: Maybe<Scalars["String"]>;
  readonly label?: Maybe<Scalars["String"]>;
  readonly street?: Maybe<Scalars["String"]>;
  readonly city?: Maybe<Scalars["String"]>;
  readonly state?: Maybe<Scalars["String"]>;
  readonly zipcode?: Maybe<Scalars["String"]>;
  readonly country?: Maybe<Scalars["String"]>;
};

export type PersonCreateManyWithoutCreatedByInput = {
  readonly create?: Maybe<ReadonlyArray<PersonCreateWithoutCreatedByInput>>;
  readonly connect?: Maybe<ReadonlyArray<PersonWhereUniqueInput>>;
};

export type PersonCreateWithoutCreatedByInput = {
  readonly id?: Maybe<Scalars["String"]>;
  readonly createdAt?: Maybe<Scalars["DateTime"]>;
  readonly updatedAt?: Maybe<Scalars["DateTime"]>;
  readonly contactId?: Maybe<Scalars["String"]>;
  readonly firstName?: Maybe<Scalars["String"]>;
  readonly middleName?: Maybe<Scalars["String"]>;
  readonly lastName?: Maybe<Scalars["String"]>;
  readonly birthday?: Maybe<Scalars["DateTime"]>;
  readonly thumbnail?: Maybe<Scalars["String"]>;
  readonly company?: Maybe<Scalars["String"]>;
  readonly jobTitle?: Maybe<Scalars["String"]>;
  readonly emails?: Maybe<EmailCreateManyWithoutPersonInput>;
  readonly phones?: Maybe<PhoneCreateManyWithoutPersonInput>;
  readonly addresses?: Maybe<AddressCreateManyWithoutPersonInput>;
  readonly notes?: Maybe<NoteCreateManyWithoutPersonInput>;
};

export type NoteCreateManyWithoutPersonInput = {
  readonly create?: Maybe<ReadonlyArray<NoteCreateWithoutPersonInput>>;
  readonly connect?: Maybe<ReadonlyArray<NoteWhereUniqueInput>>;
};

export type NoteCreateWithoutPersonInput = {
  readonly id?: Maybe<Scalars["String"]>;
  readonly createdAt?: Maybe<Scalars["DateTime"]>;
  readonly metAt?: Maybe<Scalars["DateTime"]>;
  readonly updatedAt?: Maybe<Scalars["DateTime"]>;
  readonly content: Scalars["String"];
};

export type DeviceInput = {
  readonly id: Scalars["ID"];
  readonly name: Scalars["String"];
};

export type AuthPayload = {
  readonly __typename?: "AuthPayload";
  readonly accessToken?: Maybe<Scalars["String"]>;
  readonly refreshToken?: Maybe<Scalars["String"]>;
};

export type PersonInput = {
  readonly id?: Maybe<Scalars["ID"]>;
  readonly contactId?: Maybe<Scalars["String"]>;
  readonly firstName?: Maybe<Scalars["String"]>;
  readonly middleName?: Maybe<Scalars["String"]>;
  readonly lastName?: Maybe<Scalars["String"]>;
  readonly thumbnail?: Maybe<Scalars["String"]>;
  readonly birthday?: Maybe<Scalars["String"]>;
  readonly jobTitle?: Maybe<Scalars["String"]>;
  readonly company?: Maybe<Scalars["String"]>;
  readonly emails?: Maybe<ReadonlyArray<EmailCreateWithoutPersonInput>>;
  readonly phones?: Maybe<ReadonlyArray<PhoneCreateWithoutPersonInput>>;
  readonly addresses?: Maybe<ReadonlyArray<AddressCreateWithoutPersonInput>>;
};

export type NoteCreateInput = {
  readonly id?: Maybe<Scalars["String"]>;
  readonly createdAt?: Maybe<Scalars["DateTime"]>;
  readonly metAt?: Maybe<Scalars["DateTime"]>;
  readonly updatedAt?: Maybe<Scalars["DateTime"]>;
  readonly content: Scalars["String"];
  readonly person: PersonCreateOneWithoutNotesInput;
};

export type PersonUpdateInput = {
  readonly id?: Maybe<Scalars["String"]>;
  readonly createdAt?: Maybe<Scalars["DateTime"]>;
  readonly updatedAt?: Maybe<Scalars["DateTime"]>;
  readonly contactId?: Maybe<Scalars["String"]>;
  readonly firstName?: Maybe<Scalars["String"]>;
  readonly middleName?: Maybe<Scalars["String"]>;
  readonly lastName?: Maybe<Scalars["String"]>;
  readonly birthday?: Maybe<Scalars["DateTime"]>;
  readonly thumbnail?: Maybe<Scalars["String"]>;
  readonly company?: Maybe<Scalars["String"]>;
  readonly jobTitle?: Maybe<Scalars["String"]>;
  readonly emails?: Maybe<EmailUpdateManyWithoutPersonInput>;
  readonly phones?: Maybe<PhoneUpdateManyWithoutPersonInput>;
  readonly addresses?: Maybe<AddressUpdateManyWithoutPersonInput>;
  readonly notes?: Maybe<NoteUpdateManyWithoutPersonInput>;
};

export type EmailUpdateManyWithoutPersonInput = {
  readonly create?: Maybe<ReadonlyArray<EmailCreateWithoutPersonInput>>;
  readonly connect?: Maybe<ReadonlyArray<EmailWhereUniqueInput>>;
  readonly set?: Maybe<ReadonlyArray<EmailWhereUniqueInput>>;
  readonly disconnect?: Maybe<ReadonlyArray<EmailWhereUniqueInput>>;
  readonly delete?: Maybe<ReadonlyArray<EmailWhereUniqueInput>>;
  readonly update?: Maybe<ReadonlyArray<EmailUpdateWithWhereUniqueWithoutPersonInput>>;
  readonly updateMany?: Maybe<ReadonlyArray<EmailUpdateManyWithWhereNestedInput>>;
  readonly deleteMany?: Maybe<ReadonlyArray<EmailScalarWhereInput>>;
  readonly upsert?: Maybe<ReadonlyArray<EmailUpsertWithWhereUniqueWithoutPersonInput>>;
};

export type EmailUpdateWithWhereUniqueWithoutPersonInput = {
  readonly where: EmailWhereUniqueInput;
  readonly data: EmailUpdateWithoutPersonDataInput;
};

export type EmailUpdateWithoutPersonDataInput = {
  readonly id?: Maybe<Scalars["String"]>;
  readonly label?: Maybe<Scalars["String"]>;
  readonly email?: Maybe<Scalars["String"]>;
};

export type EmailUpdateManyWithWhereNestedInput = {
  readonly where: EmailScalarWhereInput;
  readonly data: EmailUpdateManyDataInput;
};

export type EmailScalarWhereInput = {
  readonly id?: Maybe<StringFilter>;
  readonly label?: Maybe<NullableStringFilter>;
  readonly email?: Maybe<StringFilter>;
  readonly AND?: Maybe<ReadonlyArray<EmailScalarWhereInput>>;
  readonly OR?: Maybe<ReadonlyArray<EmailScalarWhereInput>>;
  readonly NOT?: Maybe<ReadonlyArray<EmailScalarWhereInput>>;
};

export type EmailUpdateManyDataInput = {
  readonly id?: Maybe<Scalars["String"]>;
  readonly label?: Maybe<Scalars["String"]>;
  readonly email?: Maybe<Scalars["String"]>;
};

export type EmailUpsertWithWhereUniqueWithoutPersonInput = {
  readonly where: EmailWhereUniqueInput;
  readonly update: EmailUpdateWithoutPersonDataInput;
  readonly create: EmailCreateWithoutPersonInput;
};

export type PhoneUpdateManyWithoutPersonInput = {
  readonly create?: Maybe<ReadonlyArray<PhoneCreateWithoutPersonInput>>;
  readonly connect?: Maybe<ReadonlyArray<PhoneWhereUniqueInput>>;
  readonly set?: Maybe<ReadonlyArray<PhoneWhereUniqueInput>>;
  readonly disconnect?: Maybe<ReadonlyArray<PhoneWhereUniqueInput>>;
  readonly delete?: Maybe<ReadonlyArray<PhoneWhereUniqueInput>>;
  readonly update?: Maybe<ReadonlyArray<PhoneUpdateWithWhereUniqueWithoutPersonInput>>;
  readonly updateMany?: Maybe<ReadonlyArray<PhoneUpdateManyWithWhereNestedInput>>;
  readonly deleteMany?: Maybe<ReadonlyArray<PhoneScalarWhereInput>>;
  readonly upsert?: Maybe<ReadonlyArray<PhoneUpsertWithWhereUniqueWithoutPersonInput>>;
};

export type PhoneUpdateWithWhereUniqueWithoutPersonInput = {
  readonly where: PhoneWhereUniqueInput;
  readonly data: PhoneUpdateWithoutPersonDataInput;
};

export type PhoneUpdateWithoutPersonDataInput = {
  readonly id?: Maybe<Scalars["String"]>;
  readonly label?: Maybe<Scalars["String"]>;
  readonly number?: Maybe<Scalars["String"]>;
};

export type PhoneUpdateManyWithWhereNestedInput = {
  readonly where: PhoneScalarWhereInput;
  readonly data: PhoneUpdateManyDataInput;
};

export type PhoneScalarWhereInput = {
  readonly id?: Maybe<StringFilter>;
  readonly label?: Maybe<NullableStringFilter>;
  readonly number?: Maybe<StringFilter>;
  readonly AND?: Maybe<ReadonlyArray<PhoneScalarWhereInput>>;
  readonly OR?: Maybe<ReadonlyArray<PhoneScalarWhereInput>>;
  readonly NOT?: Maybe<ReadonlyArray<PhoneScalarWhereInput>>;
};

export type PhoneUpdateManyDataInput = {
  readonly id?: Maybe<Scalars["String"]>;
  readonly label?: Maybe<Scalars["String"]>;
  readonly number?: Maybe<Scalars["String"]>;
};

export type PhoneUpsertWithWhereUniqueWithoutPersonInput = {
  readonly where: PhoneWhereUniqueInput;
  readonly update: PhoneUpdateWithoutPersonDataInput;
  readonly create: PhoneCreateWithoutPersonInput;
};

export type AddressUpdateManyWithoutPersonInput = {
  readonly create?: Maybe<ReadonlyArray<AddressCreateWithoutPersonInput>>;
  readonly connect?: Maybe<ReadonlyArray<AddressWhereUniqueInput>>;
  readonly set?: Maybe<ReadonlyArray<AddressWhereUniqueInput>>;
  readonly disconnect?: Maybe<ReadonlyArray<AddressWhereUniqueInput>>;
  readonly delete?: Maybe<ReadonlyArray<AddressWhereUniqueInput>>;
  readonly update?: Maybe<ReadonlyArray<AddressUpdateWithWhereUniqueWithoutPersonInput>>;
  readonly updateMany?: Maybe<ReadonlyArray<AddressUpdateManyWithWhereNestedInput>>;
  readonly deleteMany?: Maybe<ReadonlyArray<AddressScalarWhereInput>>;
  readonly upsert?: Maybe<ReadonlyArray<AddressUpsertWithWhereUniqueWithoutPersonInput>>;
};

export type AddressUpdateWithWhereUniqueWithoutPersonInput = {
  readonly where: AddressWhereUniqueInput;
  readonly data: AddressUpdateWithoutPersonDataInput;
};

export type AddressUpdateWithoutPersonDataInput = {
  readonly id?: Maybe<Scalars["String"]>;
  readonly label?: Maybe<Scalars["String"]>;
  readonly street?: Maybe<Scalars["String"]>;
  readonly city?: Maybe<Scalars["String"]>;
  readonly state?: Maybe<Scalars["String"]>;
  readonly zipcode?: Maybe<Scalars["String"]>;
  readonly country?: Maybe<Scalars["String"]>;
};

export type AddressUpdateManyWithWhereNestedInput = {
  readonly where: AddressScalarWhereInput;
  readonly data: AddressUpdateManyDataInput;
};

export type AddressScalarWhereInput = {
  readonly id?: Maybe<StringFilter>;
  readonly label?: Maybe<NullableStringFilter>;
  readonly street?: Maybe<NullableStringFilter>;
  readonly city?: Maybe<NullableStringFilter>;
  readonly state?: Maybe<NullableStringFilter>;
  readonly zipcode?: Maybe<NullableStringFilter>;
  readonly country?: Maybe<NullableStringFilter>;
  readonly AND?: Maybe<ReadonlyArray<AddressScalarWhereInput>>;
  readonly OR?: Maybe<ReadonlyArray<AddressScalarWhereInput>>;
  readonly NOT?: Maybe<ReadonlyArray<AddressScalarWhereInput>>;
};

export type AddressUpdateManyDataInput = {
  readonly id?: Maybe<Scalars["String"]>;
  readonly label?: Maybe<Scalars["String"]>;
  readonly street?: Maybe<Scalars["String"]>;
  readonly city?: Maybe<Scalars["String"]>;
  readonly state?: Maybe<Scalars["String"]>;
  readonly zipcode?: Maybe<Scalars["String"]>;
  readonly country?: Maybe<Scalars["String"]>;
};

export type AddressUpsertWithWhereUniqueWithoutPersonInput = {
  readonly where: AddressWhereUniqueInput;
  readonly update: AddressUpdateWithoutPersonDataInput;
  readonly create: AddressCreateWithoutPersonInput;
};

export type NoteUpdateManyWithoutPersonInput = {
  readonly create?: Maybe<ReadonlyArray<NoteCreateWithoutPersonInput>>;
  readonly connect?: Maybe<ReadonlyArray<NoteWhereUniqueInput>>;
  readonly set?: Maybe<ReadonlyArray<NoteWhereUniqueInput>>;
  readonly disconnect?: Maybe<ReadonlyArray<NoteWhereUniqueInput>>;
  readonly delete?: Maybe<ReadonlyArray<NoteWhereUniqueInput>>;
  readonly update?: Maybe<ReadonlyArray<NoteUpdateWithWhereUniqueWithoutPersonInput>>;
  readonly updateMany?: Maybe<ReadonlyArray<NoteUpdateManyWithWhereNestedInput>>;
  readonly deleteMany?: Maybe<ReadonlyArray<NoteScalarWhereInput>>;
  readonly upsert?: Maybe<ReadonlyArray<NoteUpsertWithWhereUniqueWithoutPersonInput>>;
};

export type NoteUpdateWithWhereUniqueWithoutPersonInput = {
  readonly where: NoteWhereUniqueInput;
  readonly data: NoteUpdateWithoutPersonDataInput;
};

export type NoteUpdateWithoutPersonDataInput = {
  readonly id?: Maybe<Scalars["String"]>;
  readonly createdAt?: Maybe<Scalars["DateTime"]>;
  readonly metAt?: Maybe<Scalars["DateTime"]>;
  readonly updatedAt?: Maybe<Scalars["DateTime"]>;
  readonly content?: Maybe<Scalars["String"]>;
};

export type NoteUpdateManyWithWhereNestedInput = {
  readonly where: NoteScalarWhereInput;
  readonly data: NoteUpdateManyDataInput;
};

export type NoteScalarWhereInput = {
  readonly id?: Maybe<StringFilter>;
  readonly createdAt?: Maybe<DateTimeFilter>;
  readonly metAt?: Maybe<DateTimeFilter>;
  readonly updatedAt?: Maybe<DateTimeFilter>;
  readonly content?: Maybe<StringFilter>;
  readonly AND?: Maybe<ReadonlyArray<NoteScalarWhereInput>>;
  readonly OR?: Maybe<ReadonlyArray<NoteScalarWhereInput>>;
  readonly NOT?: Maybe<ReadonlyArray<NoteScalarWhereInput>>;
};

export type NoteUpdateManyDataInput = {
  readonly id?: Maybe<Scalars["String"]>;
  readonly createdAt?: Maybe<Scalars["DateTime"]>;
  readonly metAt?: Maybe<Scalars["DateTime"]>;
  readonly updatedAt?: Maybe<Scalars["DateTime"]>;
  readonly content?: Maybe<Scalars["String"]>;
};

export type NoteUpsertWithWhereUniqueWithoutPersonInput = {
  readonly where: NoteWhereUniqueInput;
  readonly update: NoteUpdateWithoutPersonDataInput;
  readonly create: NoteCreateWithoutPersonInput;
};

export type AccessToken = {
  readonly __typename?: "AccessToken";
  readonly token: Scalars["String"];
  readonly id: Scalars["String"];
  readonly expiresAt: Scalars["String"];
};

export type LoginMutationVariables = {
  username: Scalars["String"];
  password: Scalars["String"];
};

export type LoginMutation = {
  readonly __typename?: "Mutation";
  readonly login?: Maybe<{
    readonly __typename?: "AuthPayload";
    readonly accessToken?: Maybe<string>;
    readonly refreshToken?: Maybe<string>;
  }>;
};

export type LogoutMutationVariables = {};

export type LogoutMutation = { readonly __typename?: "Mutation"; readonly logout?: Maybe<boolean> };

export type GetAccessTokenQueryVariables = {};

export type GetAccessTokenQuery = {
  readonly __typename?: "Query";
  readonly accessToken?: Maybe<{
    readonly __typename?: "AccessToken";
    readonly token: string;
    readonly id: string;
    readonly expiresAt: string;
  }>;
};

export type GetCurrentUserQueryVariables = {};

export type GetCurrentUserQuery = {
  readonly __typename?: "Query";
  readonly currentUser?: Maybe<{
    readonly __typename?: "User";
    readonly id: string;
    readonly email: string;
    readonly username: string;
    readonly firstName: string;
    readonly lastName: string;
  }>;
};

export type SetAuthDetailsMutationVariables = {
  accessToken: Scalars["String"];
};

export type SetAuthDetailsMutation = {
  readonly __typename?: "Mutation";
  readonly setAuthDetails?: Maybe<string>;
};

export type CreateNoteMutationVariables = {
  person: PersonInput;
  metAt?: Maybe<Scalars["String"]>;
  content: Scalars["String"];
};

export type CreateNoteMutation = {
  readonly __typename?: "Mutation";
  readonly createNote?: Maybe<{
    readonly __typename?: "Note";
    readonly id: string;
    readonly metAt: any;
    readonly content: string;
    readonly person: {
      readonly __typename?: "Person";
      readonly id: string;
      readonly contactId?: Maybe<string>;
      readonly firstName?: Maybe<string>;
      readonly lastName?: Maybe<string>;
      readonly thumbnail?: Maybe<string>;
      readonly jobTitle?: Maybe<string>;
      readonly company?: Maybe<string>;
      readonly phones: ReadonlyArray<{
        readonly __typename?: "Phone";
        readonly id: string;
        readonly label?: Maybe<string>;
        readonly number: string;
      }>;
      readonly emails: ReadonlyArray<{
        readonly __typename?: "Email";
        readonly id: string;
        readonly label?: Maybe<string>;
        readonly email: string;
      }>;
    };
  }>;
};

export type DeleteNoteMutationVariables = {
  id: Scalars["String"];
};

export type DeleteNoteMutation = {
  readonly __typename?: "Mutation";
  readonly deleteNote?: Maybe<{ readonly __typename?: "Note"; readonly id: string }>;
};

export type GetAllNotesQueryVariables = {};

export type GetAllNotesQuery = {
  readonly __typename?: "Query";
  readonly notes?: Maybe<
    ReadonlyArray<{
      readonly __typename?: "Note";
      readonly id: string;
      readonly metAt: any;
      readonly content: string;
      readonly person: {
        readonly __typename?: "Person";
        readonly id: string;
        readonly contactId?: Maybe<string>;
        readonly firstName?: Maybe<string>;
        readonly lastName?: Maybe<string>;
        readonly thumbnail?: Maybe<string>;
        readonly jobTitle?: Maybe<string>;
        readonly company?: Maybe<string>;
      };
    }>
  >;
};

export type GetNoteQueryVariables = {
  id: Scalars["String"];
};

export type GetNoteQuery = {
  readonly __typename?: "Query";
  readonly note?: Maybe<{
    readonly __typename?: "Note";
    readonly id: string;
    readonly metAt: any;
    readonly content: string;
    readonly person: {
      readonly __typename?: "Person";
      readonly id: string;
      readonly contactId?: Maybe<string>;
      readonly firstName?: Maybe<string>;
      readonly lastName?: Maybe<string>;
      readonly thumbnail?: Maybe<string>;
      readonly jobTitle?: Maybe<string>;
      readonly company?: Maybe<string>;
    };
  }>;
};

export type GetPersonQueryVariables = {
  id: Scalars["String"];
};

export type GetPersonQuery = {
  readonly __typename?: "Query";
  readonly person?: Maybe<{
    readonly __typename?: "Person";
    readonly id: string;
    readonly contactId?: Maybe<string>;
    readonly firstName?: Maybe<string>;
    readonly lastName?: Maybe<string>;
    readonly thumbnail?: Maybe<string>;
    readonly jobTitle?: Maybe<string>;
    readonly company?: Maybe<string>;
    readonly phones: ReadonlyArray<{
      readonly __typename?: "Phone";
      readonly id: string;
      readonly label?: Maybe<string>;
      readonly number: string;
    }>;
    readonly emails: ReadonlyArray<{
      readonly __typename?: "Email";
      readonly id: string;
      readonly label?: Maybe<string>;
      readonly email: string;
    }>;
    readonly notes: ReadonlyArray<{
      readonly __typename?: "Note";
      readonly id: string;
      readonly metAt: any;
      readonly content: string;
    }>;
  }>;
};

export type SearchPeopleQueryVariables = {
  searchString: Scalars["String"];
};

export type SearchPeopleQuery = {
  readonly __typename?: "Query";
  readonly peopleSearch?: Maybe<
    ReadonlyArray<{
      readonly __typename?: "Person";
      readonly id: string;
      readonly contactId?: Maybe<string>;
      readonly firstName?: Maybe<string>;
      readonly lastName?: Maybe<string>;
      readonly thumbnail?: Maybe<string>;
      readonly jobTitle?: Maybe<string>;
      readonly company?: Maybe<string>;
    }>
  >;
};

export const NoteDataFragmentDoc = gql`
  fragment NoteData on Note {
    id
    metAt
    content
  }
`;
export const ContactDataFragmentDoc = gql`
  fragment ContactData on Person {
    phones {
      id
      label
      number
    }
    emails {
      id
      label
      email
    }
  }
`;
export const PersonDataFragmentDoc = gql`
  fragment PersonData on Person {
    id
    contactId
    firstName
    lastName
    thumbnail
  }
`;
export const WorkDataFragmentDoc = gql`
  fragment WorkData on Person {
    jobTitle
    company
  }
`;
export const LoginDocument = gql`
  mutation Login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      accessToken
      refreshToken
    }
  }
`;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>
) {
  return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    baseOptions
  );
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
export const LogoutDocument = gql`
  mutation Logout {
    logout
  }
`;
export type LogoutMutationFn = ApolloReactCommon.MutationFunction<
  LogoutMutation,
  LogoutMutationVariables
>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<LogoutMutation, LogoutMutationVariables>
) {
  return ApolloReactHooks.useMutation<LogoutMutation, LogoutMutationVariables>(
    LogoutDocument,
    baseOptions
  );
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = ApolloReactCommon.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<
  LogoutMutation,
  LogoutMutationVariables
>;
export const GetAccessTokenDocument = gql`
  query GetAccessToken {
    accessToken @client {
      token
      id
      expiresAt
    }
  }
`;

/**
 * __useGetAccessTokenQuery__
 *
 * To run a query within a React component, call `useGetAccessTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccessTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccessTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccessTokenQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetAccessTokenQuery, GetAccessTokenQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetAccessTokenQuery, GetAccessTokenQueryVariables>(
    GetAccessTokenDocument,
    baseOptions
  );
}
export function useGetAccessTokenLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAccessTokenQuery,
    GetAccessTokenQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetAccessTokenQuery, GetAccessTokenQueryVariables>(
    GetAccessTokenDocument,
    baseOptions
  );
}
export type GetAccessTokenQueryHookResult = ReturnType<typeof useGetAccessTokenQuery>;
export type GetAccessTokenLazyQueryHookResult = ReturnType<typeof useGetAccessTokenLazyQuery>;
export type GetAccessTokenQueryResult = ApolloReactCommon.QueryResult<
  GetAccessTokenQuery,
  GetAccessTokenQueryVariables
>;
export const GetCurrentUserDocument = gql`
  query GetCurrentUser {
    currentUser @client {
      id
      email
      username
      firstName
      lastName
    }
  }
`;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    baseOptions
  );
}
export function useGetCurrentUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    baseOptions
  );
}
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserQueryResult = ApolloReactCommon.QueryResult<
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables
>;
export const SetAuthDetailsDocument = gql`
  mutation SetAuthDetails($accessToken: String!) {
    setAuthDetails(accessToken: $accessToken) @client
  }
`;
export type SetAuthDetailsMutationFn = ApolloReactCommon.MutationFunction<
  SetAuthDetailsMutation,
  SetAuthDetailsMutationVariables
>;

/**
 * __useSetAuthDetailsMutation__
 *
 * To run a mutation, you first call `useSetAuthDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAuthDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAuthDetailsMutation, { data, loading, error }] = useSetAuthDetailsMutation({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useSetAuthDetailsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SetAuthDetailsMutation,
    SetAuthDetailsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<SetAuthDetailsMutation, SetAuthDetailsMutationVariables>(
    SetAuthDetailsDocument,
    baseOptions
  );
}
export type SetAuthDetailsMutationHookResult = ReturnType<typeof useSetAuthDetailsMutation>;
export type SetAuthDetailsMutationResult = ApolloReactCommon.MutationResult<SetAuthDetailsMutation>;
export type SetAuthDetailsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SetAuthDetailsMutation,
  SetAuthDetailsMutationVariables
>;
export const CreateNoteDocument = gql`
  mutation CreateNote($person: PersonInput!, $metAt: String, $content: String!) {
    createNote(person: $person, metAt: $metAt, content: $content) {
      ...NoteData
      person {
        ...PersonData
        ...ContactData
        ...WorkData
      }
    }
  }
  ${NoteDataFragmentDoc}
  ${PersonDataFragmentDoc}
  ${ContactDataFragmentDoc}
  ${WorkDataFragmentDoc}
`;
export type CreateNoteMutationFn = ApolloReactCommon.MutationFunction<
  CreateNoteMutation,
  CreateNoteMutationVariables
>;

/**
 * __useCreateNoteMutation__
 *
 * To run a mutation, you first call `useCreateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNoteMutation, { data, loading, error }] = useCreateNoteMutation({
 *   variables: {
 *      person: // value for 'person'
 *      metAt: // value for 'metAt'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useCreateNoteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateNoteMutation,
    CreateNoteMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateNoteMutation, CreateNoteMutationVariables>(
    CreateNoteDocument,
    baseOptions
  );
}
export type CreateNoteMutationHookResult = ReturnType<typeof useCreateNoteMutation>;
export type CreateNoteMutationResult = ApolloReactCommon.MutationResult<CreateNoteMutation>;
export type CreateNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateNoteMutation,
  CreateNoteMutationVariables
>;
export const DeleteNoteDocument = gql`
  mutation DeleteNote($id: String!) {
    deleteNote(id: $id) {
      id
    }
  }
`;
export type DeleteNoteMutationFn = ApolloReactCommon.MutationFunction<
  DeleteNoteMutation,
  DeleteNoteMutationVariables
>;

/**
 * __useDeleteNoteMutation__
 *
 * To run a mutation, you first call `useDeleteNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNoteMutation, { data, loading, error }] = useDeleteNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNoteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteNoteMutation,
    DeleteNoteMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeleteNoteMutation, DeleteNoteMutationVariables>(
    DeleteNoteDocument,
    baseOptions
  );
}
export type DeleteNoteMutationHookResult = ReturnType<typeof useDeleteNoteMutation>;
export type DeleteNoteMutationResult = ApolloReactCommon.MutationResult<DeleteNoteMutation>;
export type DeleteNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteNoteMutation,
  DeleteNoteMutationVariables
>;
export const GetAllNotesDocument = gql`
  query GetAllNotes {
    notes(orderBy: { metAt: desc }) {
      ...NoteData
      person {
        ...PersonData
        ...WorkData
      }
    }
  }
  ${NoteDataFragmentDoc}
  ${PersonDataFragmentDoc}
  ${WorkDataFragmentDoc}
`;

/**
 * __useGetAllNotesQuery__
 *
 * To run a query within a React component, call `useGetAllNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllNotesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllNotesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllNotesQuery, GetAllNotesQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetAllNotesQuery, GetAllNotesQueryVariables>(
    GetAllNotesDocument,
    baseOptions
  );
}
export function useGetAllNotesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllNotesQuery, GetAllNotesQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetAllNotesQuery, GetAllNotesQueryVariables>(
    GetAllNotesDocument,
    baseOptions
  );
}
export type GetAllNotesQueryHookResult = ReturnType<typeof useGetAllNotesQuery>;
export type GetAllNotesLazyQueryHookResult = ReturnType<typeof useGetAllNotesLazyQuery>;
export type GetAllNotesQueryResult = ApolloReactCommon.QueryResult<
  GetAllNotesQuery,
  GetAllNotesQueryVariables
>;
export const GetNoteDocument = gql`
  query GetNote($id: String!) {
    note(where: { id: $id }) {
      ...NoteData
      person {
        ...PersonData
        ...WorkData
      }
    }
  }
  ${NoteDataFragmentDoc}
  ${PersonDataFragmentDoc}
  ${WorkDataFragmentDoc}
`;

/**
 * __useGetNoteQuery__
 *
 * To run a query within a React component, call `useGetNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNoteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetNoteQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetNoteQuery, GetNoteQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetNoteQuery, GetNoteQueryVariables>(
    GetNoteDocument,
    baseOptions
  );
}
export function useGetNoteLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNoteQuery, GetNoteQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetNoteQuery, GetNoteQueryVariables>(
    GetNoteDocument,
    baseOptions
  );
}
export type GetNoteQueryHookResult = ReturnType<typeof useGetNoteQuery>;
export type GetNoteLazyQueryHookResult = ReturnType<typeof useGetNoteLazyQuery>;
export type GetNoteQueryResult = ApolloReactCommon.QueryResult<GetNoteQuery, GetNoteQueryVariables>;
export const GetPersonDocument = gql`
  query GetPerson($id: String!) {
    person(where: { id: $id }) {
      ...PersonData
      ...WorkData
      ...ContactData
      notes(orderBy: { metAt: desc }) {
        ...NoteData
      }
    }
  }
  ${PersonDataFragmentDoc}
  ${WorkDataFragmentDoc}
  ${ContactDataFragmentDoc}
  ${NoteDataFragmentDoc}
`;

/**
 * __useGetPersonQuery__
 *
 * To run a query within a React component, call `useGetPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPersonQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetPersonQuery, GetPersonQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetPersonQuery, GetPersonQueryVariables>(
    GetPersonDocument,
    baseOptions
  );
}
export function useGetPersonLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPersonQuery, GetPersonQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetPersonQuery, GetPersonQueryVariables>(
    GetPersonDocument,
    baseOptions
  );
}
export type GetPersonQueryHookResult = ReturnType<typeof useGetPersonQuery>;
export type GetPersonLazyQueryHookResult = ReturnType<typeof useGetPersonLazyQuery>;
export type GetPersonQueryResult = ApolloReactCommon.QueryResult<
  GetPersonQuery,
  GetPersonQueryVariables
>;
export const SearchPeopleDocument = gql`
  query SearchPeople($searchString: String!) {
    peopleSearch(searchString: $searchString) {
      ...PersonData
      ...WorkData
    }
  }
  ${PersonDataFragmentDoc}
  ${WorkDataFragmentDoc}
`;

/**
 * __useSearchPeopleQuery__
 *
 * To run a query within a React component, call `useSearchPeopleQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPeopleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPeopleQuery({
 *   variables: {
 *      searchString: // value for 'searchString'
 *   },
 * });
 */
export function useSearchPeopleQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<SearchPeopleQuery, SearchPeopleQueryVariables>
) {
  return ApolloReactHooks.useQuery<SearchPeopleQuery, SearchPeopleQueryVariables>(
    SearchPeopleDocument,
    baseOptions
  );
}
export function useSearchPeopleLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchPeopleQuery, SearchPeopleQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<SearchPeopleQuery, SearchPeopleQueryVariables>(
    SearchPeopleDocument,
    baseOptions
  );
}
export type SearchPeopleQueryHookResult = ReturnType<typeof useSearchPeopleQuery>;
export type SearchPeopleLazyQueryHookResult = ReturnType<typeof useSearchPeopleLazyQuery>;
export type SearchPeopleQueryResult = ApolloReactCommon.QueryResult<
  SearchPeopleQuery,
  SearchPeopleQueryVariables
>;

export type ResolverTypeWrapper<T> = Promise<T> | T;

export type StitchingResolver<TResult, TParent, TContext, TArgs> = {
  fragment: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | StitchingResolver<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterator<TResult> | Promise<AsyncIterator<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs
> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<
  TResult,
  TKey extends string,
  TParent = {},
  TContext = {},
  TArgs = {}
> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type isTypeOfResolverFn<T = {}> = (
  obj: T,
  info: GraphQLResolveInfo
) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  String: ResolverTypeWrapper<Scalars["String"]>;
  Boolean: ResolverTypeWrapper<Scalars["Boolean"]>;
  Query: ResolverTypeWrapper<{}>;
  Int: ResolverTypeWrapper<Scalars["Int"]>;
  ContextUser: ResolverTypeWrapper<ContextUser>;
  NoteWhereUniqueInput: NoteWhereUniqueInput;
  Note: ResolverTypeWrapper<Note>;
  DateTime: ResolverTypeWrapper<Scalars["DateTime"]>;
  User: ResolverTypeWrapper<User>;
  NoteWhereInput: NoteWhereInput;
  StringFilter: StringFilter;
  DateTimeFilter: DateTimeFilter;
  PersonWhereInput: PersonWhereInput;
  NullableStringFilter: NullableStringFilter;
  EmailFilter: EmailFilter;
  EmailWhereInput: EmailWhereInput;
  PhoneFilter: PhoneFilter;
  PhoneWhereInput: PhoneWhereInput;
  AddressFilter: AddressFilter;
  AddressWhereInput: AddressWhereInput;
  NullableDateTimeFilter: NullableDateTimeFilter;
  NoteFilter: NoteFilter;
  NoteOrderByInput: NoteOrderByInput;
  OrderByArg: OrderByArg;
  Person: ResolverTypeWrapper<Person>;
  EmailWhereUniqueInput: EmailWhereUniqueInput;
  Email: ResolverTypeWrapper<Email>;
  PhoneWhereUniqueInput: PhoneWhereUniqueInput;
  Phone: ResolverTypeWrapper<Phone>;
  AddressWhereUniqueInput: AddressWhereUniqueInput;
  Address: ResolverTypeWrapper<Address>;
  PersonWhereUniqueInput: PersonWhereUniqueInput;
  PersonOrderByInput: PersonOrderByInput;
  PeopleGroup: ResolverTypeWrapper<PeopleGroup>;
  RefreshTokenWhereUniqueInput: RefreshTokenWhereUniqueInput;
  RefreshToken: ResolverTypeWrapper<RefreshToken>;
  Device: ResolverTypeWrapper<Device>;
  RefreshTokenWhereInput: RefreshTokenWhereInput;
  BooleanFilter: BooleanFilter;
  DeviceWhereInput: DeviceWhereInput;
  RefreshTokenFilter: RefreshTokenFilter;
  SearchResult: ResolversTypes["Person"] | ResolversTypes["Note"];
  UserWhereUniqueInput: UserWhereUniqueInput;
  UserWhereInput: UserWhereInput;
  PersonFilter: PersonFilter;
  UserOrderByInput: UserOrderByInput;
  Mutation: ResolverTypeWrapper<{}>;
  ID: ResolverTypeWrapper<Scalars["ID"]>;
  UserCreateInput: UserCreateInput;
  UserCreatedeviceTokensInput: UserCreatedeviceTokensInput;
  NoteCreateManyWithoutAuthorInput: NoteCreateManyWithoutAuthorInput;
  NoteCreateWithoutAuthorInput: NoteCreateWithoutAuthorInput;
  PersonCreateOneWithoutNotesInput: PersonCreateOneWithoutNotesInput;
  PersonCreateWithoutNotesInput: PersonCreateWithoutNotesInput;
  EmailCreateManyWithoutPersonInput: EmailCreateManyWithoutPersonInput;
  EmailCreateWithoutPersonInput: EmailCreateWithoutPersonInput;
  PhoneCreateManyWithoutPersonInput: PhoneCreateManyWithoutPersonInput;
  PhoneCreateWithoutPersonInput: PhoneCreateWithoutPersonInput;
  AddressCreateManyWithoutPersonInput: AddressCreateManyWithoutPersonInput;
  AddressCreateWithoutPersonInput: AddressCreateWithoutPersonInput;
  PersonCreateManyWithoutCreatedByInput: PersonCreateManyWithoutCreatedByInput;
  PersonCreateWithoutCreatedByInput: PersonCreateWithoutCreatedByInput;
  NoteCreateManyWithoutPersonInput: NoteCreateManyWithoutPersonInput;
  NoteCreateWithoutPersonInput: NoteCreateWithoutPersonInput;
  DeviceInput: DeviceInput;
  AuthPayload: ResolverTypeWrapper<AuthPayload>;
  PersonInput: PersonInput;
  NoteCreateInput: NoteCreateInput;
  PersonUpdateInput: PersonUpdateInput;
  EmailUpdateManyWithoutPersonInput: EmailUpdateManyWithoutPersonInput;
  EmailUpdateWithWhereUniqueWithoutPersonInput: EmailUpdateWithWhereUniqueWithoutPersonInput;
  EmailUpdateWithoutPersonDataInput: EmailUpdateWithoutPersonDataInput;
  EmailUpdateManyWithWhereNestedInput: EmailUpdateManyWithWhereNestedInput;
  EmailScalarWhereInput: EmailScalarWhereInput;
  EmailUpdateManyDataInput: EmailUpdateManyDataInput;
  EmailUpsertWithWhereUniqueWithoutPersonInput: EmailUpsertWithWhereUniqueWithoutPersonInput;
  PhoneUpdateManyWithoutPersonInput: PhoneUpdateManyWithoutPersonInput;
  PhoneUpdateWithWhereUniqueWithoutPersonInput: PhoneUpdateWithWhereUniqueWithoutPersonInput;
  PhoneUpdateWithoutPersonDataInput: PhoneUpdateWithoutPersonDataInput;
  PhoneUpdateManyWithWhereNestedInput: PhoneUpdateManyWithWhereNestedInput;
  PhoneScalarWhereInput: PhoneScalarWhereInput;
  PhoneUpdateManyDataInput: PhoneUpdateManyDataInput;
  PhoneUpsertWithWhereUniqueWithoutPersonInput: PhoneUpsertWithWhereUniqueWithoutPersonInput;
  AddressUpdateManyWithoutPersonInput: AddressUpdateManyWithoutPersonInput;
  AddressUpdateWithWhereUniqueWithoutPersonInput: AddressUpdateWithWhereUniqueWithoutPersonInput;
  AddressUpdateWithoutPersonDataInput: AddressUpdateWithoutPersonDataInput;
  AddressUpdateManyWithWhereNestedInput: AddressUpdateManyWithWhereNestedInput;
  AddressScalarWhereInput: AddressScalarWhereInput;
  AddressUpdateManyDataInput: AddressUpdateManyDataInput;
  AddressUpsertWithWhereUniqueWithoutPersonInput: AddressUpsertWithWhereUniqueWithoutPersonInput;
  NoteUpdateManyWithoutPersonInput: NoteUpdateManyWithoutPersonInput;
  NoteUpdateWithWhereUniqueWithoutPersonInput: NoteUpdateWithWhereUniqueWithoutPersonInput;
  NoteUpdateWithoutPersonDataInput: NoteUpdateWithoutPersonDataInput;
  NoteUpdateManyWithWhereNestedInput: NoteUpdateManyWithWhereNestedInput;
  NoteScalarWhereInput: NoteScalarWhereInput;
  NoteUpdateManyDataInput: NoteUpdateManyDataInput;
  NoteUpsertWithWhereUniqueWithoutPersonInput: NoteUpsertWithWhereUniqueWithoutPersonInput;
  AccessToken: ResolverTypeWrapper<AccessToken>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  String: Scalars["String"];
  Boolean: Scalars["Boolean"];
  Query: {};
  Int: Scalars["Int"];
  ContextUser: ContextUser;
  NoteWhereUniqueInput: NoteWhereUniqueInput;
  Note: Note;
  DateTime: Scalars["DateTime"];
  User: User;
  NoteWhereInput: NoteWhereInput;
  StringFilter: StringFilter;
  DateTimeFilter: DateTimeFilter;
  PersonWhereInput: PersonWhereInput;
  NullableStringFilter: NullableStringFilter;
  EmailFilter: EmailFilter;
  EmailWhereInput: EmailWhereInput;
  PhoneFilter: PhoneFilter;
  PhoneWhereInput: PhoneWhereInput;
  AddressFilter: AddressFilter;
  AddressWhereInput: AddressWhereInput;
  NullableDateTimeFilter: NullableDateTimeFilter;
  NoteFilter: NoteFilter;
  NoteOrderByInput: NoteOrderByInput;
  OrderByArg: OrderByArg;
  Person: Person;
  EmailWhereUniqueInput: EmailWhereUniqueInput;
  Email: Email;
  PhoneWhereUniqueInput: PhoneWhereUniqueInput;
  Phone: Phone;
  AddressWhereUniqueInput: AddressWhereUniqueInput;
  Address: Address;
  PersonWhereUniqueInput: PersonWhereUniqueInput;
  PersonOrderByInput: PersonOrderByInput;
  PeopleGroup: PeopleGroup;
  RefreshTokenWhereUniqueInput: RefreshTokenWhereUniqueInput;
  RefreshToken: RefreshToken;
  Device: Device;
  RefreshTokenWhereInput: RefreshTokenWhereInput;
  BooleanFilter: BooleanFilter;
  DeviceWhereInput: DeviceWhereInput;
  RefreshTokenFilter: RefreshTokenFilter;
  SearchResult: ResolversParentTypes["Person"] | ResolversParentTypes["Note"];
  UserWhereUniqueInput: UserWhereUniqueInput;
  UserWhereInput: UserWhereInput;
  PersonFilter: PersonFilter;
  UserOrderByInput: UserOrderByInput;
  Mutation: {};
  ID: Scalars["ID"];
  UserCreateInput: UserCreateInput;
  UserCreatedeviceTokensInput: UserCreatedeviceTokensInput;
  NoteCreateManyWithoutAuthorInput: NoteCreateManyWithoutAuthorInput;
  NoteCreateWithoutAuthorInput: NoteCreateWithoutAuthorInput;
  PersonCreateOneWithoutNotesInput: PersonCreateOneWithoutNotesInput;
  PersonCreateWithoutNotesInput: PersonCreateWithoutNotesInput;
  EmailCreateManyWithoutPersonInput: EmailCreateManyWithoutPersonInput;
  EmailCreateWithoutPersonInput: EmailCreateWithoutPersonInput;
  PhoneCreateManyWithoutPersonInput: PhoneCreateManyWithoutPersonInput;
  PhoneCreateWithoutPersonInput: PhoneCreateWithoutPersonInput;
  AddressCreateManyWithoutPersonInput: AddressCreateManyWithoutPersonInput;
  AddressCreateWithoutPersonInput: AddressCreateWithoutPersonInput;
  PersonCreateManyWithoutCreatedByInput: PersonCreateManyWithoutCreatedByInput;
  PersonCreateWithoutCreatedByInput: PersonCreateWithoutCreatedByInput;
  NoteCreateManyWithoutPersonInput: NoteCreateManyWithoutPersonInput;
  NoteCreateWithoutPersonInput: NoteCreateWithoutPersonInput;
  DeviceInput: DeviceInput;
  AuthPayload: AuthPayload;
  PersonInput: PersonInput;
  NoteCreateInput: NoteCreateInput;
  PersonUpdateInput: PersonUpdateInput;
  EmailUpdateManyWithoutPersonInput: EmailUpdateManyWithoutPersonInput;
  EmailUpdateWithWhereUniqueWithoutPersonInput: EmailUpdateWithWhereUniqueWithoutPersonInput;
  EmailUpdateWithoutPersonDataInput: EmailUpdateWithoutPersonDataInput;
  EmailUpdateManyWithWhereNestedInput: EmailUpdateManyWithWhereNestedInput;
  EmailScalarWhereInput: EmailScalarWhereInput;
  EmailUpdateManyDataInput: EmailUpdateManyDataInput;
  EmailUpsertWithWhereUniqueWithoutPersonInput: EmailUpsertWithWhereUniqueWithoutPersonInput;
  PhoneUpdateManyWithoutPersonInput: PhoneUpdateManyWithoutPersonInput;
  PhoneUpdateWithWhereUniqueWithoutPersonInput: PhoneUpdateWithWhereUniqueWithoutPersonInput;
  PhoneUpdateWithoutPersonDataInput: PhoneUpdateWithoutPersonDataInput;
  PhoneUpdateManyWithWhereNestedInput: PhoneUpdateManyWithWhereNestedInput;
  PhoneScalarWhereInput: PhoneScalarWhereInput;
  PhoneUpdateManyDataInput: PhoneUpdateManyDataInput;
  PhoneUpsertWithWhereUniqueWithoutPersonInput: PhoneUpsertWithWhereUniqueWithoutPersonInput;
  AddressUpdateManyWithoutPersonInput: AddressUpdateManyWithoutPersonInput;
  AddressUpdateWithWhereUniqueWithoutPersonInput: AddressUpdateWithWhereUniqueWithoutPersonInput;
  AddressUpdateWithoutPersonDataInput: AddressUpdateWithoutPersonDataInput;
  AddressUpdateManyWithWhereNestedInput: AddressUpdateManyWithWhereNestedInput;
  AddressScalarWhereInput: AddressScalarWhereInput;
  AddressUpdateManyDataInput: AddressUpdateManyDataInput;
  AddressUpsertWithWhereUniqueWithoutPersonInput: AddressUpsertWithWhereUniqueWithoutPersonInput;
  NoteUpdateManyWithoutPersonInput: NoteUpdateManyWithoutPersonInput;
  NoteUpdateWithWhereUniqueWithoutPersonInput: NoteUpdateWithWhereUniqueWithoutPersonInput;
  NoteUpdateWithoutPersonDataInput: NoteUpdateWithoutPersonDataInput;
  NoteUpdateManyWithWhereNestedInput: NoteUpdateManyWithWhereNestedInput;
  NoteScalarWhereInput: NoteScalarWhereInput;
  NoteUpdateManyDataInput: NoteUpdateManyDataInput;
  NoteUpsertWithWhereUniqueWithoutPersonInput: NoteUpsertWithWhereUniqueWithoutPersonInput;
  AccessToken: AccessToken;
};

export type ClientDirectiveArgs = {};

export type ClientDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = ClientDirectiveArgs
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type QueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["Query"] = ResolversParentTypes["Query"]
> = {
  accessToken?: Resolver<Maybe<ResolversTypes["AccessToken"]>, ParentType, ContextType>;
  currentUser?: Resolver<Maybe<ResolversTypes["User"]>, ParentType, ContextType>;
  me?: Resolver<Maybe<ResolversTypes["ContextUser"]>, ParentType, ContextType>;
  note?: Resolver<
    Maybe<ResolversTypes["Note"]>,
    ParentType,
    ContextType,
    RequireFields<QueryNoteArgs, "where">
  >;
  notes?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes["Note"]>>,
    ParentType,
    ContextType,
    RequireFields<QueryNotesArgs, never>
  >;
  notesRaw?: Resolver<
    ReadonlyArray<ResolversTypes["Note"]>,
    ParentType,
    ContextType,
    RequireFields<QueryNotesRawArgs, never>
  >;
  notesSearch?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes["Note"]>>,
    ParentType,
    ContextType,
    RequireFields<QueryNotesSearchArgs, "searchString">
  >;
  people?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes["Person"]>>,
    ParentType,
    ContextType,
    RequireFields<QueryPeopleArgs, never>
  >;
  peopleGroups?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes["PeopleGroup"]>>,
    ParentType,
    ContextType,
    RequireFields<QueryPeopleGroupsArgs, "orderBy">
  >;
  peopleRaw?: Resolver<
    ReadonlyArray<ResolversTypes["Person"]>,
    ParentType,
    ContextType,
    RequireFields<QueryPeopleRawArgs, never>
  >;
  peopleSearch?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes["Person"]>>,
    ParentType,
    ContextType,
    RequireFields<QueryPeopleSearchArgs, "searchString">
  >;
  person?: Resolver<
    Maybe<ResolversTypes["Person"]>,
    ParentType,
    ContextType,
    RequireFields<QueryPersonArgs, "where">
  >;
  refreshToken?: Resolver<
    Maybe<ResolversTypes["RefreshToken"]>,
    ParentType,
    ContextType,
    RequireFields<QueryRefreshTokenArgs, "where">
  >;
  refreshTokens?: Resolver<
    ReadonlyArray<ResolversTypes["RefreshToken"]>,
    ParentType,
    ContextType,
    RequireFields<QueryRefreshTokensArgs, never>
  >;
  search?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes["SearchResult"]>>,
    ParentType,
    ContextType,
    RequireFields<QuerySearchArgs, "searchString">
  >;
  user?: Resolver<
    Maybe<ResolversTypes["User"]>,
    ParentType,
    ContextType,
    RequireFields<QueryUserArgs, "where">
  >;
  users?: Resolver<
    ReadonlyArray<ResolversTypes["User"]>,
    ParentType,
    ContextType,
    RequireFields<QueryUsersArgs, never>
  >;
};

export type ContextUserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["ContextUser"] = ResolversParentTypes["ContextUser"]
> = {
  id?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  __isTypeOf?: isTypeOfResolverFn<ParentType>;
};

export type NoteResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["Note"] = ResolversParentTypes["Note"]
> = {
  id?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes["DateTime"], ParentType, ContextType>;
  metAt?: Resolver<ResolversTypes["DateTime"], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes["DateTime"], ParentType, ContextType>;
  author?: Resolver<ResolversTypes["User"], ParentType, ContextType>;
  person?: Resolver<ResolversTypes["Person"], ParentType, ContextType>;
  content?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  __isTypeOf?: isTypeOfResolverFn<ParentType>;
};

export interface DateTimeScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes["DateTime"], any> {
  name: "DateTime";
}

export type UserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["User"] = ResolversParentTypes["User"]
> = {
  id?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  email?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  username?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  password?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  notes?: Resolver<
    ReadonlyArray<ResolversTypes["Note"]>,
    ParentType,
    ContextType,
    RequireFields<UserNotesArgs, never>
  >;
  __isTypeOf?: isTypeOfResolverFn<ParentType>;
};

export type PersonResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["Person"] = ResolversParentTypes["Person"]
> = {
  id?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  createdBy?: Resolver<ResolversTypes["User"], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes["DateTime"], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes["DateTime"], ParentType, ContextType>;
  contactId?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  middleName?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  emails?: Resolver<
    ReadonlyArray<ResolversTypes["Email"]>,
    ParentType,
    ContextType,
    RequireFields<PersonEmailsArgs, never>
  >;
  phones?: Resolver<
    ReadonlyArray<ResolversTypes["Phone"]>,
    ParentType,
    ContextType,
    RequireFields<PersonPhonesArgs, never>
  >;
  addresses?: Resolver<
    ReadonlyArray<ResolversTypes["Address"]>,
    ParentType,
    ContextType,
    RequireFields<PersonAddressesArgs, never>
  >;
  birthday?: Resolver<Maybe<ResolversTypes["DateTime"]>, ParentType, ContextType>;
  thumbnail?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  company?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  jobTitle?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  notes?: Resolver<
    ReadonlyArray<ResolversTypes["Note"]>,
    ParentType,
    ContextType,
    RequireFields<PersonNotesArgs, never>
  >;
  __isTypeOf?: isTypeOfResolverFn<ParentType>;
};

export type EmailResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["Email"] = ResolversParentTypes["Email"]
> = {
  id?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  __isTypeOf?: isTypeOfResolverFn<ParentType>;
};

export type PhoneResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["Phone"] = ResolversParentTypes["Phone"]
> = {
  id?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  number?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  __isTypeOf?: isTypeOfResolverFn<ParentType>;
};

export type AddressResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["Address"] = ResolversParentTypes["Address"]
> = {
  id?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  street?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  zipcode?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  __isTypeOf?: isTypeOfResolverFn<ParentType>;
};

export type PeopleGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["PeopleGroup"] = ResolversParentTypes["PeopleGroup"]
> = {
  key?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  people?: Resolver<Maybe<ReadonlyArray<ResolversTypes["Person"]>>, ParentType, ContextType>;
  __isTypeOf?: isTypeOfResolverFn<ParentType>;
};

export type RefreshTokenResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["RefreshToken"] = ResolversParentTypes["RefreshToken"]
> = {
  id?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  hash?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  device?: Resolver<Maybe<ResolversTypes["Device"]>, ParentType, ContextType>;
  expiration?: Resolver<ResolversTypes["DateTime"], ParentType, ContextType>;
  parent?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  valid?: Resolver<ResolversTypes["Boolean"], ParentType, ContextType>;
  __isTypeOf?: isTypeOfResolverFn<ParentType>;
};

export type DeviceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["Device"] = ResolversParentTypes["Device"]
> = {
  id?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  name?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  __isTypeOf?: isTypeOfResolverFn<ParentType>;
};

export type SearchResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["SearchResult"] = ResolversParentTypes["SearchResult"]
> = {
  __resolveType: TypeResolveFn<"Person" | "Note", ParentType, ContextType>;
};

export type MutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["Mutation"] = ResolversParentTypes["Mutation"]
> = {
  addDeviceToken?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes["ID"]>>,
    ParentType,
    ContextType,
    RequireFields<MutationAddDeviceTokenArgs, "deviceToken">
  >;
  createNote?: Resolver<
    Maybe<ResolversTypes["Note"]>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateNoteArgs, "person" | "content">
  >;
  createOneNote?: Resolver<
    ResolversTypes["Note"],
    ParentType,
    ContextType,
    RequireFields<MutationCreateOneNoteArgs, "data">
  >;
  createOneUser?: Resolver<
    ResolversTypes["User"],
    ParentType,
    ContextType,
    RequireFields<MutationCreateOneUserArgs, "data">
  >;
  deleteNote?: Resolver<
    Maybe<ResolversTypes["Note"]>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteNoteArgs, "id">
  >;
  login?: Resolver<
    Maybe<ResolversTypes["AuthPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationLoginArgs, "username" | "password">
  >;
  logout?: Resolver<Maybe<ResolversTypes["Boolean"]>, ParentType, ContextType>;
  refreshTokens?: Resolver<Maybe<ResolversTypes["AuthPayload"]>, ParentType, ContextType>;
  register?: Resolver<
    Maybe<ResolversTypes["AuthPayload"]>,
    ParentType,
    ContextType,
    RequireFields<MutationRegisterArgs, "user">
  >;
  setAuthDetails?: Resolver<
    Maybe<ResolversTypes["String"]>,
    ParentType,
    ContextType,
    RequireFields<MutationSetAuthDetailsArgs, "accessToken">
  >;
  updateOnePerson?: Resolver<
    Maybe<ResolversTypes["Person"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateOnePersonArgs, "data" | "where">
  >;
  updatePerson?: Resolver<
    Maybe<ResolversTypes["Person"]>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdatePersonArgs, "id" | "person">
  >;
};

export type AuthPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["AuthPayload"] = ResolversParentTypes["AuthPayload"]
> = {
  accessToken?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  refreshToken?: Resolver<Maybe<ResolversTypes["String"]>, ParentType, ContextType>;
  __isTypeOf?: isTypeOfResolverFn<ParentType>;
};

export type AccessTokenResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes["AccessToken"] = ResolversParentTypes["AccessToken"]
> = {
  token?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  id?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  expiresAt?: Resolver<ResolversTypes["String"], ParentType, ContextType>;
  __isTypeOf?: isTypeOfResolverFn<ParentType>;
};

export type Resolvers<ContextType = any> = {
  Query?: QueryResolvers<ContextType>;
  ContextUser?: ContextUserResolvers<ContextType>;
  Note?: NoteResolvers<ContextType>;
  DateTime?: GraphQLScalarType;
  User?: UserResolvers<ContextType>;
  Person?: PersonResolvers<ContextType>;
  Email?: EmailResolvers<ContextType>;
  Phone?: PhoneResolvers<ContextType>;
  Address?: AddressResolvers<ContextType>;
  PeopleGroup?: PeopleGroupResolvers<ContextType>;
  RefreshToken?: RefreshTokenResolvers<ContextType>;
  Device?: DeviceResolvers<ContextType>;
  SearchResult?: SearchResultResolvers;
  Mutation?: MutationResolvers<ContextType>;
  AuthPayload?: AuthPayloadResolvers<ContextType>;
  AccessToken?: AccessTokenResolvers<ContextType>;
};

/**
 * @deprecated
 * Use "Resolvers" root object instead. If you wish to get "IResolvers", add "typesPrefix: I" to your config.
 */
export type IResolvers<ContextType = any> = Resolvers<ContextType>;
export type DirectiveResolvers<ContextType = any> = {
  client?: ClientDirectiveResolver<any, any, ContextType>;
};

/**
 * @deprecated
 * Use "DirectiveResolvers" root object instead. If you wish to get "IDirectiveResolvers", add "typesPrefix: I" to your config.
 */
export type IDirectiveResolvers<ContextType = any> = DirectiveResolvers<ContextType>;
