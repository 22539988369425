import { ApolloProvider } from "@apollo/client";
import { CSSReset, theme, ThemeProvider } from "@chakra-ui/core";
import React from "react";
import { AuthProvider, NoteProvider } from "./contexts";
import client from "./graphql";
import { useIcons } from "./hooks";
import Router from "./Router";

function App() {
  useIcons();

  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <NoteProvider>
          <ThemeProvider theme={theme}>
            <CSSReset />
            <Router />
          </ThemeProvider>
        </NoteProvider>
      </AuthProvider>
    </ApolloProvider>
  );
}

export default App;
