import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  useGetAccessTokenQuery,
  useGetCurrentUserQuery,
  useLogoutMutation,
  useSetAuthDetailsMutation,
} from "../generated/graphql";
import { BASE_URL } from "../graphql";
import { history, ROUTES } from "../Router";
import { noop } from "../utils";

interface User {
  id: string;
  email: string;
  username: string;
  firstName: string;
  lastName: string;
}

interface IAuthContext {
  accessToken: string;
  user: User | null;
  loading: boolean;
  logout: () => void;
}

const defaultContext: IAuthContext = {
  accessToken: "",
  user: null,
  loading: true,
  logout: noop,
};

const AuthContext = createContext<IAuthContext>(defaultContext);
export const useAuth = () => useContext(AuthContext);

export const AuthProvider: FC = ({ children }) => {
  const { data: accessTokenRes } = useGetAccessTokenQuery();
  const accessToken = accessTokenRes?.accessToken?.token ?? "";

  const { data: currentUserRes } = useGetCurrentUserQuery();
  let user: User | null = null;
  if (currentUserRes?.currentUser) {
    const { __typename, ...currentUser } = currentUserRes.currentUser;
    user = currentUser;
  }

  const [loading, setLoading] = useState<boolean>(true);
  const isMounted = useRef<boolean>(true);
  const [setAuthDetails] = useSetAuthDetailsMutation();
  useEffect(() => {
    const refreshTokens = async () => {
      console.info(`Trying to refresh tokens...`);
      try {
        const res = await fetch(`${BASE_URL}/refresh_token`, {
          method: "POST",
          credentials: "include",
        });
        const data: { ok: boolean; accessToken: string } = await res.json();
        if (data.accessToken && isMounted.current) {
          await setAuthDetails({ variables: { accessToken: data.accessToken } });
          console.info(`Successfully refreshed tokens`);
        }
      } catch (err) {
        console.warn(`Could not refresh tokens`);
      } finally {
        setLoading(false);
      }
    };

    refreshTokens();

    return () => {
      isMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [logoutMutation, { client }] = useLogoutMutation();
  const logout = useCallback(async () => {
    await logoutMutation();
    await client!.clearStore();
    history.replace(ROUTES.LOGIN);
  }, [client, logoutMutation]);

  const value: IAuthContext = useMemo(
    () => ({
      accessToken,
      user,
      loading,
      logout,
    }),
    [accessToken, loading, logout, user]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
