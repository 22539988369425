import React, {
  createContext,
  Ref,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  GetAllNotesDocument,
  GetAllNotesQuery,
  GetNoteDocument,
  PersonInput,
  useCreateNoteMutation,
} from "../generated/graphql";
import { getOptimisticId, noop, noopAsync, sortByKeyDesc } from "../utils";

interface INoteContext {
  person: PersonInput | null;
  setPerson: (person: PersonInput | null) => void;
  metAt: string | null;
  setMetAt: (date: string | null) => void;
  content: string;
  setContent: (content: string) => void;
  createNote: () => Promise<any>;
  contentRef: Ref<HTMLTextAreaElement>;
  focusContent: () => void;
  dirty: boolean;
  disabled: boolean;
}

const defaultContext: INoteContext = {
  person: null,
  setPerson: noop,
  metAt: null,
  setMetAt: noop,
  content: "",
  setContent: noop,
  createNote: noopAsync,
  contentRef: null,
  focusContent: noop,
  dirty: false,
  disabled: true,
};

const NoteContext = createContext<INoteContext>(defaultContext);
export const useNote = () => useContext(NoteContext);

export const NoteProvider: React.FC = ({ children }) => {
  const [person, setPerson] = useState<PersonInput | null>(null);
  const [metAt, setMetAt] = useState<string | null>(null);
  const [content, setContent] = useState<string>("");
  const contentRef = useRef<HTMLTextAreaElement>(null);

  const [createNote] = useCreateNoteMutation({
    variables: { person: person!, metAt: metAt || new Date().toISOString(), content },
    optimisticResponse: {
      __typename: "Mutation",
      createNote: {
        __typename: "Note",
        id: getOptimisticId(),
        person: {
          contactId: null,
          thumbnail: null,
          ...person,
          __typename: "Person",
          id: person?.id || getOptimisticId(),
          emails: (person?.emails ?? []).map((e) => ({
            ...e,
            __typename: "Email",
            id: getOptimisticId(),
          })),
          phones: (person?.phones ?? []).map((p) => ({
            ...p,
            __typename: "Phone",
            id: getOptimisticId(),
          })),
        },
        metAt: metAt || Date.now(),
        content,
      },
    },
    update: (cache, { data }) => {
      if (!data?.createNote) return;
      const createdNote = data.createNote;

      try {
        // Check if notes exist in the cache yet
        const notesData = cache.readQuery<GetAllNotesQuery>({ query: GetAllNotesDocument });

        const newNotes = [createdNote, ...(notesData?.notes ?? [])];
        newNotes.sort(sortByKeyDesc("metAt"));

        // If they already exist, update the list
        cache.writeQuery({
          query: GetAllNotesDocument,
          data: {
            ...notesData,
            notes: newNotes,
          },
        });
      } catch {
        console.info(`Could not find notes in cache`);
      }

      cache.writeQuery({
        query: GetNoteDocument,
        variables: { id: createdNote?.id },
        data: { note: createdNote },
      });
    },
    onCompleted: () => {
      setPerson(null);
      setMetAt(null);
      setContent("");
    },
  });

  const focusContent = useCallback(() => {
    contentRef.current?.focus();
  }, []);

  const dirty = useMemo(() => !!(person || content), [content, person]);
  const disabled = useMemo(() => !person || !content, [content, person]);

  const value: INoteContext = useMemo(
    () => ({
      person,
      setPerson,
      metAt,
      setMetAt,
      content,
      setContent,
      createNote,
      contentRef,
      focusContent,
      dirty,
      disabled,
    }),
    [content, createNote, dirty, disabled, focusContent, metAt, person]
  );

  return <NoteContext.Provider value={value}>{children}</NoteContext.Provider>;
};
